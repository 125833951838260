import React, { Component } from "react";
import '../../css/nnbox.scss';
import { Select } from "antd";

const { Option } = Select;

class CardModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDetail: false,
            selectMenu: 1,

            // 요약 -> 부가서비스 클릭
            openAdditional01: false,
            // 적립 -> 캐시백 클릭
            openCashback: true,
            // 적립 -> 부가서비스 클릭
            openAdditional02: false,
            // 연회비 -> 부가서비스 클릭
            openAdditional03: false,
        }
    }

    render() {
        const { isOpen, close } = this.props;
        return (
            <React.Fragment>
                {
                    isOpen ?
                        <React.Fragment>
                            <div className="Modal-overlay" onClick={close} />
                            <div className="Modal">
                                <div className="card-title">
                                    삼성카드 CORPORATE #2
                                    <div className="card-image" onClick={close}>
                                        <img src={require('../../img/common/close.png').default} alt="" />
                                    </div>
                                </div>
                                <div className="card-content">
                                    <div>
                                        <img src={require('../../img/common/franchise/samsungCard.png').default} alt="" />
                                    </div>
                                    {/* 셀렉트 박스 */}
                                    <div className="card-menu">
                                        {this.state.openDetail ?
                                            <>
                                                <div onClick={() => this.setState({ openDetail: false })}>
                                                    [국내] 연회비 없음
                                                    <img src={require('../../img/common/up-arrow.png').default} alt="" />
                                                </div>
                                                <div>[MASTER] 연회비 없음</div>
                                                <div>[VISA] 연회비 없음</div>
                                                <div>[AMEX] 연회비 없음</div>
                                                <div>[UnionPay] 연회비 없음</div>
                                            </>
                                            :
                                            <>
                                                <div onClick={() => this.setState({ openDetail: true })}>
                                                    [국내] 연회비 없음
                                                    <img src={require('../../img/common/down-arrow.png').default} alt="" />
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div onClick={() => this.setState({ openDetail: true })}>
                                        <img src={require('../../img/footer/info.png').default} alt="" />
                                        법인개별/법인공용
                                    </div>

                                    {/* 메뉴탭 */}
                                    <div className="card-menu-tab">
                                        <div className={this.state.selectMenu == 1 ? "card-menu-tab-01-after" : "card-menu-tab-01"}
                                            onClick={() => this.setState({ selectMenu: 1 })}>요약</div>
                                        <div className={this.state.selectMenu == 2 ? "card-menu-tab-01-after" : "card-menu-tab-01"}
                                            onClick={() => this.setState({ selectMenu: 2 })}>적립</div>
                                        <div className={this.state.selectMenu == 3 ? "card-menu-tab-01-after" : "card-menu-tab-01"}
                                            onClick={() => this.setState({ selectMenu: 3 })}>연회비</div>
                                    </div>

                                    {/* 요약 컨텐츠 */}
                                    {this.state.selectMenu == 1 &&
                                        <div className="selectMenu-01">
                                            <div onClick={() => this.setState({ selectMenu: 2 })} className="selectMenu-01-top">
                                                <div className="selectMenu-01-top-logo">P</div>
                                                <div className="selectMenu-01-top-txt">
                                                    적립
                                                    <li style={{ fontSize: 17, fontWeight: '500', letterSpacing: -1.27 }}>국내외 캐시백 0.2% 캐시백</li>
                                                </div>
                                                <div className="selectMenu-01-top-img">
                                                    <img src={require('../../img/common/right-arrow.png').default} alt="" />
                                                </div>
                                            </div>
                                            <div className="selectMenu-01-mid">
                                                {/* {!this.state.openAdditional01 ?  */}
                                                <div
                                                    onClick={() => this.state.openAdditional01 ?
                                                        this.setState({ openAdditional01: false }) : this.setState({ openAdditional01: true })
                                                    }>
                                                    <img className="selectMenu-01-info-blue-logo" src={require('../../img/footer/info_blue.png').default} alt="" />
                                                    <div className="selectMenu-01-mid-img">
                                                        {
                                                            this.state.openAdditional01 ?
                                                                <img src={require('../../img/common/up-arrow.png').default} alt="" />
                                                                :
                                                                <img src={require('../../img/common/down-arrow.png').default} alt="" />
                                                        }
                                                    </div>
                                                    <div className="selectMenu-01-mid-title">부가서비스 변경 가능 사유</div>
                                                </div>
                                                {this.state.openAdditional01 &&
                                                    <div className="selectMenu-01-mid-txt">
                                                        *&nbsp; 카드를 이용하는 경우 제공되는 포인트 및 할인혜택 등의 부가서비스는 카드 신규 출시 이후 다음<br />
                                                        &nbsp;&nbsp; 사유 중 어느 하나에 해당하는 경우 외에는 변경할 수 없습니다.(단, 회원의 권익을 증진하거나<br />
                                                        &nbsp;&nbsp; 부담을 완화하는 경우는 제외) 카드사가 부가서비스를 변경하는 경우에는 변경사유, <br />
                                                        &nbsp;&nbsp; 변경 내용 등을 다음에서 정하는 기간에 따라 서면교부, 우편 또는 전자우편, 전화 또는 팩스, <br />
                                                        &nbsp;&nbsp; 휴대폰 메시지 또는 이에 준하는 전자적 의사표시 중 2가지 이상의 방법으로 고지하여 드립니다.<br />
                                                        <br />
                                                        ①카드사의 휴업·파산·경영상의 위기 등에 따른 불가피한 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ②제휴업체의 휴업·파산·경영상의 위기로 인해 불가피하게 부가서비스를 축소·변경하는 경우로서<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;다른 제휴업체를 통해 동종의 유사한 부가서비스 제공이 불가한 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ③제휴업체가 카드사의 의사에 반하여 해당 부가서비스를 축소하거나 변경 시, 당초 부가서비스에<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;상응하는 다른 부가서비스를 제공하는 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ④부가서비스를 3년 이상 제공한 상태에서 해당 부가서비스로 인해 상품의 수익성이 현저히 낮아진<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;경우 : 부가서비스 변경일 6개월 전부터 매월
                                                    </div>
                                                }
                                            </div>

                                            <div className="selectMenu-01-bot">
                                                <div className="selectMenu-01-bot-title">
                                                    필수 안내 사항
                                                </div>
                                                <div className="selectMenu-01-bot-txt">
                                                    ㆍ연체이자율 : 회원별·이용상품별 정상이자율 + 3%p (최고 연 20.0%)<br />
                                                    &nbsp;&nbsp;&nbsp;- 연체 발생 시점에 정상이자율이 없는 경우 아래와 같이 적용<br />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ① 일시불 거래 연체 시 : 거래 발생 시점의 최소 기간 (2개월) 유이자할부 이자율<br />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ② 무이자할부 거래 연체 시 : 거래 발생 시점의 동일한 할부 계약기간 유이자할부 이자율<br />
                                                    <br />
                                                    ㆍ금융상품 이용 전 상품설명서, 약관을 통해 이용조건을 확인해 주시기 바랍니다.<br />
                                                    <br />
                                                    ㆍ필요 이상으로 신용카드를 발급 및 이용하실 경우 개인신용평점, 이용한도 등에 영향을 미칠 수 <br />
                                                    &nbsp;&nbsp;&nbsp; 있습니다.<br />
                                                    <br />
                                                    <strong>ㆍ상환 능력에 비해 신용카드 사용액이 과도할 경우 귀하의 개인신용평점이 하락할 수 있습니다.</strong><br />
                                                    <br />
                                                    <strong>ㆍ개인신용평점 하락 시 금융거래 관련된 불이익이 발생할 수 있습니다.</strong><br />
                                                    <br />
                                                    <strong>ㆍ일정 기간 원리금을 연체할 경우, 모든 원리금을 변제할 의무가 발생할 수 있습니다.</strong><br />
                                                    <br />
                                                    ㆍ금융소비자는 해당 상품 또는 서비스에 대하여 설명을 받을 권리가 있습니다.<br />
                                                    <br />
                                                    ㆍ신용카드 발급이 부적정한 경우(개인신용평점 낮음 등) 카드 발급이 제한될 수 있습니다.<br />
                                                    <br />
                                                    ㆍ카드 이용대금과 이에 수반되는 모든 수수료를 지정된 대금 결제일에 상환합니다.<br />
                                                    <br />
                                                    ㆍ심의번호 : S3629-20-1421(2020.09.02)<br />
                                                    <br />
                                                    ㆍ카드 출시일자 : 2020-09-16
                                                </div>
                                            </div>
                                        </div>

                                    }

                                    {this.state.selectMenu == 2 &&
                                        <div className="selectMenu-02">
                                            <div
                                                onClick={() => !this.state.openCashback ?
                                                    this.setState({ openCashback: true }) : this.setState({ openCashback: false })
                                                }
                                                className="selectMenu-02-top">
                                                <div className="selectMenu-02-top-img">
                                                    {
                                                        this.state.openCashback ?
                                                            <img src={require('../../img/common/up-arrow.png').default} alt="" />
                                                            :
                                                            <img src={require('../../img/common/down-arrow.png').default} alt="" />
                                                    }
                                                </div>
                                                <div className="selectMenu-02-top-txt">
                                                    국내외 0.2% 캐시백
                                                </div>
                                            </div>


                                            {this.state.openCashback &&
                                                <>
                                                    <div className="selectMenu-02-mid-tag-txt">
                                                        <strong>서비스안내</strong><br />
                                                        - 전월 이용금액에 관계없이, 캐시백한도 없이 국내외 가맹점 0.2% 캐시백
                                                    </div>
                                                    <div className="selectMenu-02-bot">
                                                        <div className="selectMenu-02-bot-txt">
                                                            <strong>유의사항</strong>
                                                        </div>
                                                        <div className="selectMenu-02-line"></div>
                                                        <div className="selectMenu-02-bot-txt2">
                                                            - 삼성카드 가맹점 업종 분류 기준에 의한 등록 가맹점에 한합니다.<br />
                                                            - <strong>건강보험/국민연금/고용보험/산재보험 및 장애인 고용부담금, 국세/지방세/공과금 납부건은 캐시백에서<br />
                                                                &nbsp;&nbsp; 제외됩니다.</strong><br />
                                                            - 삼성카드는 아래 항목을 공과금으로 분류합니다.<br />
                                                            &nbsp;&nbsp;ㆍ전기요금, 사회보험료(건강보험/국민연금/고용보험/산재보험),우편요금, 도시가스요금, 여권 발급비용,<br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp; 상하수도요금, 과태료, 범칙금, 벌금, 국가·공공기관·공공단체(지방자치단체, 공공조합,영조물, 공재단)<br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp; 에서 개설한 가맹점 이용금액<br />
                                                            - 해외의 경우, 해외겸용카드에 한해 혜택이 제공됩니다.<br />
                                                            - 해외 이용 시 별도의 수수료가 부과됩니다.<br />
                                                            - 해외에서 카드 결제 시, 현지 통화가 아닌 원화로 결제하는 경우 해외원화결제 서비스(DCC) 수수료가<br />
                                                            &nbsp;&nbsp;&nbsp;추가로 발생할 수 있으므로 유의하시기 바랍니다.<br />
                                                            - 해외 이용 시(해외 사이트 거래 포함) 미화(USD) 기준 거래미화금액에 접수일의 우리은행 최초 고시<br />
                                                            &nbsp;&nbsp;&nbsp;전신환매도율을 적용한 후, 국제브랜드사가 부과하는 브랜드사수수료(Mastercard 1%, VISA 1.1%,<br />
                                                            &nbsp;&nbsp;&nbsp;아멕스 1.4%, UnionPay 0.8%)와 삼성카드가 부과하는 해외이용수수료(0.2%)를 합산하여 원화로<br />
                                                            &nbsp;&nbsp;&nbsp;청구됩니다.<br />
                                                            - 브랜드사수수료의 경우 국제브랜드사 정책에 따라 변경될 수 있습니다.<br />
                                                            - 해외 이용 시 청구금액 산출방법은 아래와 같습니다.<br />
                                                            &nbsp;&nbsp;ㆍ해외 이용 시 청구금액 = (거래미화금액 × 전신환매도율①)+ 브랜드사수수료② + 해외이용수수료③<br />
                                                            &nbsp;&nbsp;① 전신환매도율 : 접수일의 우리은행 최초 고시 전신환매도율<br />
                                                            &nbsp;&nbsp;② 브랜드사수수료 = [거래미화금액 × 국제브랜드 이용 수수료율 <br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Mastercard 1%, VISA 1.1%, 아멕스 1.4%, UnionPay 0.8%)]× 전신환매도율<br />
                                                            &nbsp;&nbsp;③ 해외이용수수료 = (거래미화금액 × 해외서비스 수수료율 0.2%)× 전신환매도율<br />
                                                            - 결제 취소건의 경우, 매출취소전표 접수월의 이용금액에서 차감 반영됩니다.<br />
                                                            - 포인트가 적립된 결제건이 취소될 경우, 해당 포인트는 보유 포인트에서 자동 차감되며,<br />
                                                            &nbsp;&nbsp; 포인트 부족 시 카드 결제계좌에서 자동 출금됩니다.<br />
                                                            - 삼성카드와 구매캐시백 포인트 제공 계약을 체결한 일부 의약품/유류 구매한도 대금 결제 시,<br />
                                                            &nbsp;&nbsp; 구매캐시백 혜택은 중복 적용되지 않습니다.<br />
                                                            - 캐시백의 유효기간은 5년으로, 유효기간 만료 시 월 단위로 자동 소멸됩니다.<br />
                                                            - 이용조건, 제공방법 등 자세한 내용은 카드 신청 시 안내받으실 수 있습니다.
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <div className="selectMenu-02-line2"></div>

                                            <div className="selectMenu-02-mid">
                                                <div
                                                    onClick={() => this.state.openAdditional02 ?
                                                        this.setState({ openAdditional02: false }) : this.setState({ openAdditional02: true })
                                                    }>
                                                    <img className="selectMenu-02-info-blue-logo" src={require('../../img/footer/info_blue.png').default} alt="" />
                                                    <div className="selectMenu-02-mid-img">
                                                        {
                                                            this.state.openAdditional02 ?
                                                                <img src={require('../../img/common/up-arrow.png').default} alt="" />
                                                                :
                                                                <img src={require('../../img/common/down-arrow.png').default} alt="" />
                                                        }
                                                    </div>
                                                    <div className="selectMenu-02-mid-title">부가서비스 변경 가능 사유</div>
                                                </div>
                                                {this.state.openAdditional02 &&
                                                    <div className="selectMenu-02-mid-txt">
                                                        *&nbsp; 카드를 이용하는 경우 제공되는 포인트 및 할인혜택 등의 부가서비스는 카드 신규 출시 이후 다음<br />
                                                        &nbsp;&nbsp; 사유 중 어느 하나에 해당하는 경우 외에는 변경할 수 없습니다.(단, 회원의 권익을 증진하거나<br />
                                                        &nbsp;&nbsp; 부담을 완화하는 경우는 제외) 카드사가 부가서비스를 변경하는 경우에는 변경사유, 변경 내용<br />
                                                        &nbsp;&nbsp; 등을 다음에서 정하는 기간에 따라 서면교부, 우편 또는 전자우편, 전화 또는 팩스, 휴대폰 메시지<br />
                                                        &nbsp;&nbsp; 또는 이에 준하는 전자적 의사표시 중 2가지 이상의 방법으로 고지하여 드립니다.<br />
                                                        <br />
                                                        ①카드사의 휴업·파산·경영상의 위기 등에 따른 불가피한 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ②제휴업체의 휴업·파산·경영상의 위기로 인해 불가피하게 부가서비스를 축소·변경하는 경우로서<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;다른 제휴업체를 통해 동종의 유사한 부가서비스 제공이 불가한 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ③제휴업체가 카드사의 의사에 반하여 해당 부가서비스를 축소하거나 변경 시, 당초 부가서비스에<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;상응하는 다른 부가서비스를 제공하는 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ④부가서비스를 3년 이상 제공한 상태에서 해당 부가서비스로 인해 상품의 수익성이 현저히 낮아진<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;경우 : 부가서비스 변경일 6개월 전부터 매월
                                                    </div>
                                                }
                                            </div>

                                            <div className="selectMenu-02-bot2 ">
                                                <div className="selectMenu-02-bot-title">
                                                    필수 안내 사항
                                                </div>
                                                <div className="selectMenu-02-bot-txt3">
                                                    ㆍ연체이자율 : 회원별·이용상품별 정상이자율 + 3%p (최고 연 20.0%)<br />
                                                    &nbsp;&nbsp;&nbsp;- 연체 발생 시점에 정상이자율이 없는 경우 아래와 같이 적용<br />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ① 일시불 거래 연체 시 : 거래 발생 시점의 최소 기간 (2개월) 유이자할부 이자율<br />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ② 무이자할부 거래 연체 시 : 거래 발생 시점의 동일한 할부 계약기간 유이자할부 이자율<br />
                                                    <br />
                                                    ㆍ금융상품 이용 전 상품설명서, 약관을 통해 이용조건을 확인해 주시기 바랍니다.<br />
                                                    <br />
                                                    ㆍ필요 이상으로 신용카드를 발급 및 이용하실 경우 개인신용평점, 이용한도 등에 영향을 미칠 수 <br />
                                                    &nbsp;&nbsp;&nbsp; 있습니다.<br />
                                                    <br />
                                                    <strong>ㆍ상환 능력에 비해 신용카드 사용액이 과도할 경우 귀하의 개인신용평점이 하락할 수 있습니다.</strong><br />
                                                    <br />
                                                    <strong>ㆍ개인신용평점 하락 시 금융거래 관련된 불이익이 발생할 수 있습니다.</strong><br />
                                                    <br />
                                                    <strong>ㆍ일정 기간 원리금을 연체할 경우, 모든 원리금을 변제할 의무가 발생할 수 있습니다.</strong><br />
                                                    <br />
                                                    ㆍ금융소비자는 해당 상품 또는 서비스에 대하여 설명을 받을 권리가 있습니다.<br />
                                                    <br />
                                                    ㆍ신용카드 발급이 부적정한 경우(개인신용평점 낮음 등) 카드 발급이 제한될 수 있습니다.<br />
                                                    <br />
                                                    ㆍ카드 이용대금과 이에 수반되는 모든 수수료를 지정된 대금 결제일에 상환합니다.<br />
                                                    <br />
                                                    ㆍ심의번호 : S3629-20-1421(2020.09.02)<br />
                                                    <br />
                                                    ㆍ카드 출시일자 : 2020-09-16
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {this.state.selectMenu == 3 &&
                                        <div className="selectMenu-03">
                                            <div className="selectMenu-03-table">
                                                <table
                                                    border="1"
                                                    style={{
                                                        borderColor: '#f4f4f4',
                                                        tableLayout: "fixed",
                                                        textAlign: 'center',
                                                        backgroundColor: '#f4f4f4',
                                                    }}>
                                                    <colgroup>
                                                        <col width='200px' />
                                                        <col width='140px' />
                                                        <col width='140px' />
                                                        <col width='140px' />
                                                        <col width='140px' />
                                                        <col width='200px' />
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ padding: 30 }} rowspan="5">구분<br /></th>
                                                            <th style={{ padding: 10 }} colspan="4">해외겸용</th>
                                                            <th style={{ padding: 30 }} rowspan="2">국내전용</th>
                                                        </tr>
                                                        <tr>
                                                            <th >MasterCard</th>
                                                            <th >VISA</th>
                                                            <th >아멕스</th>
                                                            <th >UnionPay</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ padding: 10 }}>총 연회비</td>
                                                            <td style={{ backgroundColor: 'white' }} colspan="4" rowspan="3">없음</td>
                                                            <td style={{ backgroundColor: 'white' }} rowspan="3">없음</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: 10 }}>기본 연회비</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: 10 }}>제휴 연회비</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="selectMenu-03-bot">
                                                <div className="selectMenu-03-bot-txt">
                                                    <strong>유의사항</strong>
                                                </div>
                                                <div className="selectMenu-03-line"></div>
                                                <div className="selectMenu-03-bot-txt2">
                                                    》연회비는 카드 발급일(발급 첫 해에는 카드 사용등록일)을 기준으로 매년 청구됩니다.<br />
                                                    &nbsp;&nbsp;&nbsp;- 예외 기준<br />
                                                    &nbsp;&nbsp;&nbsp; ㆍ카드 발급일(발급 첫 해에는 카드 사용등록일)에 연회비가 청구되지 않은 경우 최초 이용일자 기준으로<br />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 해당 결제일에 청구됩니다.<br />
                                                    &nbsp;&nbsp;&nbsp; ㆍ매년 카드 발급일을 기준으로 직전 1년 이내 실적이 없는 경우 연회비가 청구되지 않습니다.<br />
                                                    &nbsp;&nbsp;&nbsp; ㆍ재발급, 갱신 시에는 이전 카드의 카드 발급일을 기준으로 연회비가 청구됩니다.<br />
                                                    <br />
                                                    》연회비는 기본 연회비와 제휴 연회비로 구분됩니다.<br />
                                                    <br />
                                                    》해당 카드 연회비(기본 연회비+제휴 연회비)는 카드별로 청구됩니다.<br />
                                                    <br />
                                                    》카드 중도 해지 시, 연회비 반환 금액은 회원이 카드사와 계약을 해지한 날로부터 일 단위로 나누어 계산<br />
                                                    &nbsp;&nbsp;(카드 이용이 가능하게 된 날을 기준으로 계산)하여 반환됩니다. 카드의 발행, 배송 등 카드 발급에 소요된<br />
                                                    &nbsp;&nbsp;비용 (신규 가입연도에 해당)은 반환 금액에서 제외되며, 제휴 연회비가 있는 경우에는 카드 이용 시<br />
                                                    &nbsp;&nbsp;제공되는 추가적인 혜택 등 부가 서비스 제공에 소요된 비용이 추가적으로 반환 금액에서 제외됩니다.<br />
                                                    <br />
                                                    》연회비 반환 금액은 계약을 해지한 날로부터 10일(영업일 기준) 이내에 반환해 드립니다. 부가 서비스 제공<br />
                                                    &nbsp;&nbsp;내역 확인에 시간이 소요되는 등 불가피한 사유가 있을 때에는 계약을 해지한 날로부터 3개월 이내에<br />
                                                    &nbsp;&nbsp;반환됩니다.
                                                </div>
                                            </div>

                                            <div className="selectMenu-03-mid">
                                                <div
                                                    onClick={() => this.state.openAdditional03 ?
                                                        this.setState({ openAdditional03: false }) : this.setState({ openAdditional03: true })
                                                    }>
                                                    <img className="selectMenu-03-info-blue-logo" src={require('../../img/footer/info_blue.png').default} alt="" />
                                                    <div className="selectMenu-03-mid-img">
                                                        {
                                                            this.state.openAdditional03 ?
                                                                <img src={require('../../img/common/up-arrow.png').default} alt="" />
                                                                :
                                                                <img src={require('../../img/common/down-arrow.png').default} alt="" />
                                                        }
                                                    </div>
                                                    <div className="selectMenu-03-mid-title">부가서비스 변경 가능 사유</div>
                                                </div>
                                                {this.state.openAdditional03 &&
                                                    <div className="selectMenu-03-mid-txt">
                                                        *&nbsp; 카드를 이용하는 경우 제공되는 포인트 및 할인혜택 등의 부가서비스는 카드 신규 출시 이후 다음<br />
                                                        &nbsp;&nbsp; 사유 중 어느 하나에 해당하는 경우 외에는 변경할 수 없습니다.(단, 회원의 권익을 증진하거나<br />
                                                        &nbsp;&nbsp; 부담을 완화하는 경우는 제외) 카드사가 부가서비스를 변경하는 경우에는 변경사유, 변경 내용<br />
                                                        &nbsp;&nbsp; 등을 다음에서 정하는 기간에 따라 서면교부, 우편 또는 전자우편, 전화 또는 팩스, 휴대폰 메시지<br />
                                                        &nbsp;&nbsp; 또는 이에 준하는 전자적 의사표시 중 2가지 이상의 방법으로 고지하여 드립니다.<br />
                                                        <br />
                                                        ①카드사의 휴업·파산·경영상의 위기 등에 따른 불가피한 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ②제휴업체의 휴업·파산·경영상의 위기로 인해 불가피하게 부가서비스를 축소·변경하는 경우로서<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;다른 제휴업체를 통해 동종의 유사한 부가서비스 제공이 불가한 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ③제휴업체가 카드사의 의사에 반하여 해당 부가서비스를 축소하거나 변경 시, 당초 부가서비스에<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;상응하는 다른 부가서비스를 제공하는 경우 : 사유발생 즉시<br />
                                                        <br />
                                                        ④부가서비스를 3년 이상 제공한 상태에서 해당 부가서비스로 인해 상품의 수익성이 현저히 낮아진<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp;경우 : 부가서비스 변경일 6개월 전부터 매월
                                                    </div>
                                                }
                                            </div>

                                            <div className="selectMenu-03-bot2">
                                                <div className="selectMenu-03-bot-title">
                                                    필수 안내 사항
                                                </div>
                                                <div className="selectMenu-03-bot-txt3">
                                                    ㆍ연체이자율 : 회원별·이용상품별 정상이자율 + 3%p (최고 연 20.0%)<br />
                                                    &nbsp;&nbsp;&nbsp;- 연체 발생 시점에 정상이자율이 없는 경우 아래와 같이 적용<br />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ① 일시불 거래 연체 시 : 거래 발생 시점의 최소 기간 (2개월) 유이자할부 이자율<br />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ② 무이자할부 거래 연체 시 : 거래 발생 시점의 동일한 할부 계약기간 유이자할부 이자율<br />
                                                    <br />
                                                    ㆍ금융상품 이용 전 상품설명서, 약관을 통해 이용조건을 확인해 주시기 바랍니다.<br />
                                                    <br />
                                                    ㆍ필요 이상으로 신용카드를 발급 및 이용하실 경우 개인신용평점, 이용한도 등에 영향을 미칠 수 <br />
                                                    &nbsp;&nbsp;&nbsp; 있습니다.<br />
                                                    <br />
                                                    <strong>ㆍ상환 능력에 비해 신용카드 사용액이 과도할 경우 귀하의 개인신용평점이 하락할 수 있습니다.</strong><br />
                                                    <br />
                                                    <strong>ㆍ개인신용평점 하락 시 금융거래 관련된 불이익이 발생할 수 있습니다.</strong><br />
                                                    <br />
                                                    <strong>ㆍ일정 기간 원리금을 연체할 경우, 모든 원리금을 변제할 의무가 발생할 수 있습니다.</strong><br />
                                                    <br />
                                                    ㆍ금융소비자는 해당 상품 또는 서비스에 대하여 설명을 받을 권리가 있습니다.<br />
                                                    <br />
                                                    ㆍ신용카드 발급이 부적정한 경우(개인신용평점 낮음 등) 카드 발급이 제한될 수 있습니다.<br />
                                                    <br />
                                                    ㆍ카드 이용대금과 이에 수반되는 모든 수수료를 지정된 대금 결제일에 상환합니다.<br />
                                                    <br />
                                                    ㆍ심의번호 : S3629-20-1421(2020.09.02)<br />
                                                    <br />
                                                    ㆍ카드 출시일자 : 2020-09-16
                                                </div>
                                            </div>









                                        </div>
                                    }


                                </div>

                                {/* <div className="guide-content">
                                    <div>
                                        <div className="bullet-dots" />
                                        <div className="guide-content-text">
                                            배송가이드
                                        </div>
                                    </div>
                                    <div>
                                        <div className="dots" />
                                        <div className="guide-content-text2">
                                            고객부재/ 연락두절 : MCC로 연락, 처리방법 확인
                                        </div>
                                    </div>
                                    <div>
                                        <div className="dots" />
                                        <div className="guide-content-text3">
                                            재이동 : 이동 전 MCC로 연락, 상점 승인 후 진행
                                        </div>
                                    </div>
                                    <div>
                                        <div className="dots" />
                                        <div className="guide-content-text4">
                                            반납 : 0.5 건 추가
                                        </div>
                                    </div>
                                    <div>
                                        <div className="dots" />
                                        <div className="guide-content-text5">
                                            배상 : 상품가액의 50%
                                        </div>
                                    </div>
                                    <div>
                                        <div className="guide-content-text6">
                                            ※ 고객 과실은 배송신청 주체인 상점주의 과실로 간주됩니다.
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </React.Fragment>
                        :
                        null
                }
            </React.Fragment>
        )
    }
}

export default (CardModal)