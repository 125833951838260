import React, { Component } from "react";
import '../../css/nnbox.scss';
import { Select } from "antd";

const { Option } = Select;

class PromotionModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openDetail: false,
            selectMenu: 1,

            // 요약 -> 부가서비스 클릭
            openAdditional01: false,
            // 적립 -> 캐시백 클릭
            openCashback: true,
            // 적립 -> 부가서비스 클릭
            openAdditional02: false,
            // 연회비 -> 부가서비스 클릭
            openAdditional03: false,
        }
    }

    render() {
        const { isOpen, close } = this.props;
        return (
            <React.Fragment>
                {
                    isOpen ?
                        <React.Fragment>
                            <div className="Modal-overlay" onClick={close} />
                            <div className="Modal">
                                <div className="promotion-image" onClick={close}>
                                    <img src={require('../../img/common/close.png').default} alt="" />
                                </div>

                                <div style={{ textAlign: 'center' }}>

                                    <div className="promotion-title-tag">
                                        <div>CORPORATE #2</div>
                                    </div>

                                </div>
                                <div className="promotion-title">
                                    <div>배달료 쓰면 쓸수록 돈이 되는</div>
                                    <div className="promotion-title-txt-2">
                                        <div> 배달료 전용 삼성카드 출시!</div>
                                        <div></div>
                                    </div>
                                </div>

                                <div className="promotion-content-bg">
                                    <div className="promotion-content">
                                        <div>
                                            <img style={{ height: 500 }} src={require('../../img/common/promotion-content-card.png').default} />
                                        </div>

                                        <div className="promotion-content-bg-1">
                                            <div className="promotion-content-1">
                                                <div className="promotion-content-1-title">
                                                    <div>카드 혜택안내</div>
                                                </div>
                                                <div className="promotion-content-1-txt">
                                                    <div className="promotion-content-1-txt-1">
                                                        1. 전용 삼성카드로 냠냠박스 배달료<br />
                                                        &nbsp;&nbsp;&nbsp; 충전 시 0.2% 캐시백 적립
                                                    </div>
                                                    <div className="promotion-content-1-txt-2">
                                                        2. 배달료 이외의 용도로 사용해도<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp; 0.2% 캐시백 똑같이 적립
                                                    </div>
                                                    <div>
                                                        <div className="promotion-content-1-txt-3">
                                                            3. 카드출시 기념 프로모션 이벤트 진행
                                                        </div>
                                                        <div className="promotion-content-1-txt-4">
                                                            <div>22년 3월 23일 ~ 6월 30일 배달료 충전 시</div>
                                                            <div>0.8% 배달료 추가 충전
                                                                <div className="promotion-content-1-txt-line"></div>
                                                            </div>
                                                            <div>
                                                                예)100만원 충전 시 기본 2,000원 삼성카드<br />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 캐시백 적립 + 추가 8,000원 배달료 충전
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="promotion-content-bg-2">
                                            <div className="promotion-content-2">
                                                <div className="promotion-content-2-title">
                                                    <div>유의사항</div>
                                                </div>
                                                <div className="promotion-content-2-txt">
                                                    <div className="promotion-content-2-txt-1">
                                                        1. 삼성카드 적립된 캐시백은 결제대금<br />
                                                        &nbsp;&nbsp;&nbsp; 입금 후 다음달 20일에 지급됩니다.
                                                    </div>
                                                    <div className="promotion-content-2-txt-2">
                                                        2. 추가 배달료 충전은 프로모션 종료 후<br />
                                                        &nbsp;&nbsp;&nbsp;&nbsp; 일괄 충전됩니다.
                                                    </div>
                                                    <div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </React.Fragment>
                        :
                        null
                }
            </React.Fragment>
        )
    }
}

export default (PromotionModal)