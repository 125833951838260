import { Form } from 'antd';
import React from 'react';
import { withRouter } from "react-router-dom";

class Stop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div style={{width: '100%', textAlign: 'center', paddingTop: '200px'}}>
                <div style={{display: 'inline-block'}}>
                    <img src={require('../img/common/logo_circle.png').default} />
                    <div style={{fontSize: '24px', paddingTop: '50px', fontFamily: 'NotoSansBold'}}>
                        프리미엄 배달플랫폼 <span style={{color: '#fddc00'}}>냠냠박스</span> 입니다 <br/>
                        서비스 준비중입니다.<br/>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Stop);