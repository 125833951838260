import React, { Component } from "react";
import '../../src/css/nnbox.scss';
import '../../src/css/nnbox_m.scss';
import { Select } from "antd";
import FormItem from "antd/lib/form/FormItem";


const { Option } = Select;
const options = [
    { value: '매장천사', label: '매장천사' },
    { value: '메이트포스', label: '메이트포스' },
    { value: '바나나포스', label: '바나나포스' },
    { value: '배달특급', label: '배달특급' },
    { value: '배달천재', label: '배달천재' },
    { value: '아이노포스(지니포스)', label: '아이노포스(지니포스)' },
    { value: '인천 이음', label: '인천 이음' },
    { value: '포스피드', label: '포스피드' },
    { value: '푸드테크', label: '푸드테크' },
    { value: '롯데리아', label: '롯데리아' },
    { value: '본죽&본도시락', label: '본죽&본도시락' },
    { value: 'spc', label: 'spc' },
    { value: '오케이포스(자담치킨)', label: '오케이포스(자담치킨)' },
];



class Privacy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOption: "매장천사",
        }
    }
    handleChange = (selectedOption) => {
        this.setState({ selectedOption }, () =>
            console.log(`Option selected:`, this.state.selectedOption)
        );
    };
    render() {
        const { isOpen, close } = this.props;
        const { selectedOption } = this.state;
        return (
            <div className="privacy-container">
                <div className="privacy-title">
                커넥트 9 개인정보처리방침
                </div>
                <div className="privacy-content">
                주식회사 커넥트 9(이하 “회사”라 함)는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.<br/><br/>

                1. [수집하는 개인정보의 항목, 수집∙이용 목적, 보유기간 및 수집방법]<br/>
                2. [개인정보의 제3자 제공에 관한 사항]<br/>
                3. [개인정보의 처리위탁]<br/>
                4. [개인정보 파기절차 및 방법]<br/>
                5. [이용자와 법정대리인의 권리 ∙ 의무 및 그 행사방법]<br/>
                6. [개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항]<br/>
                7. [개인정보의 기술적 ∙ 관리적 보호대책]<br/>
                8. [개인위치정보 처리에 관한 사항]<br/>
                9. [개인정보보호 책임자의 연락처]<br/>
                10. [본 개인정보처리방침의 적용 범위]<br/>
                11. [개정 전 고지 의무]

                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 1조 (수집하는 개인정보의 항목, 수집∙이용 목적, 보유기간 및 수집방법)
                </div>
                <div className="privacy-content">
                회사는 다음과 같이 개인정보를 수집하여 이용 및 보유합니다.<br/><br/>

                1. 필수 수집 항목<br/>
                <table className="privacy-table">
                    <tr>
                        <th>수집 구간</th>
                        <th>수집 구간</th>
                        <th>수집하는 개인정보 항목</th>
                        <th>개인정보 수집 및 이용 목적</th>
                        <th>개인정보 보유 및 이용기간</th>
                    </tr>
                    <tr>
                        <td>필수</td>
                        <td>홈페이지 본사 대리점 신청 문의 시</td>
                        <td>이름, 연락처, 희망지역, 문의내용</td>
                        <td>서비스 가입 의사확인, 문의사항처리</td>
                        <td>문의 종료일로부터 1년간</td>
                    </tr>
                    <tr>
                        <td>필수</td>
                        <td>홈페이지 본사 가맹점 신청 문의 시</td>
                        <td>이름, 연락처, 매장명, 주소, 운영시간, 주중/주말 배달건수, 문의내용</td>
                        <td>서비스 가입 의사확인, 문의사항처리</td>
                        <td>문의 종료일로부터 1년간</td>
                    </tr>
                    <tr>
                        <td>필수</td>
                        <td>홈페이지 본사 라이더 신청 문의 시</td>
                        <td>이름,생년월일,연락처,주소, 성별, 근무 희망지역, 라이더경력, 지원내용</td>
                        <td>지원 의사 확인, 고용 알선, 문의사항처리</td>
                        <td>채용 절차 종료일로부터 1년간</td>
                    </tr>
                    <tr>
                        <td>필수</td>
                        <td>가맹점 계약 체결 시</td>
                        <td>가맹점명, 실가맹점명, 사업자번호, 사업자등록일, 대표자명, 가맹점전화, 가맹점주소, 이메일, 사업자 생년월일,은행명, 계좌번호, 예금주, 가상 계좌번호 은행, 가상 계좌번호</td>
                        <td>계약의 체결 및 이행, 회사 플랫폼 설치 및 이용, 정산 및 지급</td>
                        <td>거래종료(계약 해지 또는 채권, 채무 분쟁 종료)시 단, 관련법령의 별도 규정이 명시되어 있는 경우 그 기간을 따름</td>
                    </tr>
                    <tr>
                        <td>필수</td>
                        <td>직영점 입점 시</td>
                        <td>지점(배달대행의 지점)의 사업자 정보, 지점 대표자 성명, 주민등록번호, 주소, 연락처, 사업장 소재지</td>
                        <td>계약의 체결 및 이행, 정산 및 지급</td>
                        <td>계약기간 만료 또는 계약해지 시 까지. 계약만료 또는 계약해지 이후 5년 뒤 파기</td>
                    </tr>
                    <tr>
                        <td>필수</td>
                        <td>대리점 입점 시</td>
                        <td>지점(배달대행의 지점)의 사업자 정보, 지점 대표자 성명, 주민등록번호, 주소, 연락처, 사업장 소재지</td>
                        <td>계약의 체결 및 이행, 정산 및 지급</td>
                        <td>계약기간 만료 또는 계약해지 시 까지. 계약만료 또는 계약해지 이후 5년 뒤 파기</td>
                    </tr>
                    <tr>
                        <td>필수</td>
                        <td>라이더 앱 가입 시</td>
                        <td>이름, 휴대폰번호, 생년월일, 예금주명, 아이디, 은행명, 계좌번호, 가상 계좌번호, 가상계좌 예금주, 차량번호, 차량정보, CI, 주민등록번호, 운전면허번호, 운전면허종별</td>
                        <td>커넥트 9 라이더앱 회원가입 처리 및 이용, 보안정책 수립, 운송수단 일치여부 확인, 운전면허 유효성 검증, 라이더 자격 확인, 고용보험 및 산재보험 신고 및 납부, 정산대금 지급, 소득 신고</td>
                        <td>거래종료(서비스 이용동의 해지, 탈퇴 또는 채권, 채무 분쟁 종료)시 단, 관련법령의 별도 규정이 명시되어 있는 경우 그 기간을 따름</td>
                    </tr>
                </table>

                2. 관련 법령에 의한 개인정보 보존 사유<br/>
                <table className="privacy-table">
                    <tr>
                        <th>근거법령</th>
                        <th>보유정보</th>
                        <th>보유기간</th>
                    </tr>
                    <tr>
                        <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                        <td>계약 또는 청약철회 등에 관한 기록</td>
                        <td>5년</td>
                    </tr>
                    <tr>
                        <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                        <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                        <td>5년</td>
                    </tr>
                    <tr>
                        <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                        <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                        <td>3년</td>
                    </tr>
                    <tr>
                        <td>통신비밀보호법</td>
                        <td>서비스 이용에 관한 로그기록</td>
                        <td>3개월</td>
                    </tr>
                </table>

                3. 개인정보 수집방법<br/>
                회사는 다음과 같은 방법으로 개인정보를 수집합니다.<br/>
                - 홈페이지 및 서비스(회사 플랫폼), 모바일기기, 전화, 상담 게시판, 이메일, 계약<br/>
                - 협력회사로부터의 제공

                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 2조 (개인정보의 제3자 제공에 관한 사항)
                </div>
                <div className="privacy-content">
                1. 회사는 세무처리와 소득신고, 고용보험료 원천공제, 고용보험 신고 및 납부내역 등 개인정보와 소득 정보 등은 “커넥트 9” 의 “플랫폼”에 전자적으로 기록되며 관련 법령과 “커넥트 9”의 개인정보처리방침 등 정책에 따라 국세청, 근로복지공단, 한국고용정보원 등 관련기관에 제공될 수 있다.<br/>
                
                <table className="privacy-table">
                    <tr>
                        <th>제공받는 자</th>
                        <th>제공하는 항목</th>
                        <th>제공목적</th>
                        <th>보유 및 이용기간</th>
                    </tr>
                    <tr>
                        <td>국세청</td>
                        <td rowSpan={3}>
                        •지점(배달대행의 지점)의 사업자정보, 지점 대표자 성명, 주민등록번호, 주소, 연락처, 사업장 소재지<br/>
                        •라이더의 개인정보는 플랫폼 내 개인정보처리방침에 별도로 동의하고 개별 수집 및 제 3자 제공
                        </td>
                        <td rowSpan={3}>플랫폼 내 계정 생성 및 관리, 세무처리 및 소득신고, 고용보험료 및 산재보험료 신고납부</td>
                        <td rowSpan={3}>계약기간 만료 또는 계약해지 시 까지.<br/>
                        계약만료 또는 계약해지 이후 5년 뒤 파기<br/>
                        제공받는 자와 계약기간 만료 시까지(주식회사 커넥트 9)<br/>
                        관련 법령의 개정, 폐지 시 까지(국세청, 근로복지공단(고용노동부))
</td>
                    </tr>
                    <tr>
                        <td>근로복지공단(고용노동부)</td>
                    </tr>
                    <tr>
                        <td>주식회사 커넥트 9</td>
                    </tr>
                </table>
                2. 회사는 정부 관계부처가 합동으로 발표한 ‘긴급상황 시 개인정보 처리 및 보호수칙’에 따라 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고, 급박한 재산 손실 등의 긴급상황이 발생하는 경우 정보주체의 동의 없이 관계기관에 개인정보를 제공할 수 있습니다. 자세한 사항은 ‘긴급상황 시 개인정보 처리 및 보호수칙’ 가이드라인을 검색하셔서 확인하실 수 있습니다.<br/>
                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 3조 (개인정보의 처리위탁)
                </div>
                <div className="privacy-content">
                회사는 개인정보를 별도로 위탁하여 처리하고 있지 않습니다.
                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 4조 (개인정보의 파기절차 및 방법)
                </div>
                <div className="privacy-content">
                회사는 원칙적으로 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.<br/>
                개인정보 파기절차 및 방법은 다음과 같습니다.<br/>
                <br/>
                1. 파기절차<br/>
                * 이용자가 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보존 사유에 따라 (개인정보의 보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.<br/>
                * 해당 개인정보는 법률에 의한 경우가 아닌 경우를 제외하고 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br/>
                <br/>
                2. 파기방법<br/>
                * 회사는 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 로우 레벨 포맷(Low Level Format) 등의 방법을 이용하여 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br/>
                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 5조 (이용자와 법정대리인의 권리・의무 및 그 행사방법)
                </div>
                <div className="privacy-content">
                1. 이용자는 회사에 대하여 언제든지 자신의 개인정보를 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
                2. 권리 행사는 개인정보보호책임자에게 전화, 서면, 전자우편을 통하여 하실 수 있으며, 이 경우 지체 없이 조치하겠습니다.<br/>
                3. 이용자의 개인정보 열람 및 처리정지에 대한 요구는 다른 법률에 특별한 규정이 있는 등의 경우에는 제한될 수 있습니다.<br/>
                4. 개인정보의 삭제에 대한 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 삭제를 요구할 수 없습니다.<br/>
                5. 이용자의 권리행사는 이용자의 법정대리인이나 위임을 받은 자 등의 대리인을 통하여도 할 수 있습니다. 이 경우 이용자의 대리인은 ‘개인정보 처리 방법에 관한 고시(제2020-7호)’ 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.<br/>
                6. 이용자는 개인정보보호법 등 관계법령을 위반하여 본 개인정보처리방침에 따라 처리하고 있는 이용자 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니됩니다.<br/>

                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 6조 (개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
                </div>
                <div className="privacy-content">
                회사는 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.<br/>
                1. 쿠키의 이용목적<br/>
                * 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br/>
                <br/>
                2. 쿠키의 설치∙운영 및 거부<br/>
                * 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.<br/>
                * 쿠키 설치 허용 여부를 지정하는 방법<br/>
                ① Internet Edge 웹 브라우저<br/>
                우측 상단 메뉴 [설정] &gt; [쿠키 및 사이트 권한] &gt; [쿠키 및 사이트 데이터 관리 및 삭제] 설정<br/>
                ② Chrome 웹 브라우저<br/>
                우측 상단 메뉴 [설정] &gt; [개인정보 및 보안] &gt; [쿠키 및 기타 사이트 데이터] 설정<br/>
                3. 모바일 서비스에서의 쿠키 설치∙운영 및 거부에 관한 사항<br/>
                * 회사는 PC 환경과 동일·유사한 인터넷 환경을 모바일 서비스에서도 제공하기 위해 모바일 기기 (예: 스마트 폰, 태블릿 PC)에서도 '쿠키(cookie)'를 사용할 수 있습니다. 회사는 관련 법령 및 규제기관의 가이드라인에 따라 쿠키를 수집하며 단, 이용자의 개인정보를 포함하여 그 자체로 개인을 식별할 수 있는 쿠키는 이용자의 사전 동의 없이 임의로 수집하지 않습니다.<br/>
                * 모바일 기기에서도 웹브라우저 설정 기능에서 쿠키 허용 여부를 선택할 수 있습니다. 모바일 기기의 운영체제 및 웹 브라우저 종류에 따라 다소 상이할 수 있지만, 대부분의 경우 모바일 웹브라우저의 환경 설정을 통해 쿠키허용 여부를 결정하거나, 기존의 쿠키 일체를 삭제할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.<br/>
                * 쿠키 설치 허용 여부를 지정하는 방법<br/>
                ① Chrome 웹 브라우저<br/>
                우측 상단 메뉴 [설정] &gt; [개인정보 및 보안] &gt; [쿠키 및 사이트 데이터] 설정<br/>
                ② Safari 웹 브라우저<br/>
                우측 상단 메뉴 [설정] &gt; [Safari] &gt; [모든 쿠키 차단] 설정<br/>
                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 7조 (개인정보의 기술적∙관리적 보호대책)

                </div>
                <div className="privacy-content">
                회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 위조, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.<br/><br/>

                1. 관리적 조치: 내부관리계획 수립․시행, 정기적 직원 교육<br/>
                2. 기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 보안프로그램 설치<br/>
                3. 물리적 조치: 전산실, 자료보관실 등의 접근통제<br/>

                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 8조 (개인위치정보 처리에 관한 사항)
                </div>
                <div className="privacy-content">
                1. 개인위치정보의 처리목적 및 보유기간<br/>
                1) 처리목적 :개인위치정보주체의 위치 파악을 통한 실시간 배달 정보, 매장 정보 제공을 통해 원활한 서비스 제공, 개인위치정보주체의 배달 현황의 실시간 위치 확인을 통한 회사의 배달 현황 관제 및 민원처리<br/>
                2) 보유기간 : 회사는 원칙적으로 최소한의 시간 동안 보유합니다.<br/>
                2. 개인위치정보 이용·제공사실 확인자료의 보유근거 및 보유기간<br/>
                회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에 근거하여 개인위치정보주체에 대한 위치정보 이용ㆍ제공사실 확인자료를 위치정보시스템에 자동으로 기록하며, 6개월간 보관합니다.<br/>
                3. 개인위치정보의 파기 절차 및 방법<br/>
                1) 종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각<br/>
                2) 전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제<br/>
                4. 개인위치정보의 제3자 제공에 관한 사항<br/>
                회사는 개인위치정보를 제3자에게 제공하지 않고 있습니다. 향후 개인위치정보주체가 지정한 제3자에게 제공이 필요한 경우에는 아래와 같은 조치를 취하겠습니다.<br/>
                1) 회사는 이용자의 동의 없이 당해 이용자의 개인위치정보를 제3자에게 제공하지 아니하며, 제3자에게 제공하는 경우 제공받는 자 및 제공 목적을 이용자에게 사전 고지하고 미리 동의를 받습니다.<br/>
                2) 회사가 이용자가 지정하는 제3자에게 개인위치정보를 제공하는 경우에는 개인위치정보를 수집한 당해 통신단말장치로 이용자에게 제공받는 자 및 제공목적을 즉시 통보합니다. 다만 아래 각호에 해당하는 경우 이용자가 미리 지정한 전자우편 또는 온라인 게시 등의 방법으로 통보할 수 있습니다.<br/>
                ① 개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신 기능을 갖추지 아니한 경우<br/>
                ② 이용자가 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우<br/>
                5. 8세 이하 아동 등의 보호의무자의 권리·의무 및 그 행사방법<br/>
                1) 회사는 아래의 경우에 해당하는 자(이하 8세 이하의 아동등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.<br/>
                * 8세 이하의 아동<br/>
                * 피성년후견인<br/>
                * 장애인복지법 제2조 제2항 제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진 및 직업재활법 제2조 제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제32조의 규정에 의하여 장애인등록을 한 자에 한한다)<br/>
                2) 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.<br/>
                3) 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.<br/>
                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 9조 (개인정보보호 책임자의 연락처)
                </div>
                <div className="privacy-content">
                회사는 개인정보에 관한 의견수렴 및 불만처리를 담당하는 개인정보보호 책임자를 지정하고 있습니다. 이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호 책임자의 연락처로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.<br/>
                ▶ 개인정보 보호책임자 (위치정보관리 책임자)<br/>
                성명 : 정진영<br/>
                직책 : 시니어<br/>
                이메일 : admin@connect9.co.kr<br/>
                전화번호 : 1600-5453<br/>
                기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/><br/>

                개인정보침해신고센터(privacy.kisa.or.kr/ 국번없이 118)<br/>
                대검찰청 사이버수사과(www.spo.go.kr/ 국번없이 1301)<br/>
                경찰청 사이버범죄 신고시스템(ecrm.police.go.kr/ 국번없이 182)<br/>
                개인정보 분쟁조정위원회(http://www.kopico.go.kr / 1833-6972)

                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 10조 (본 개인정보처리방침의 적용 범위)
                </div>
                <div className="privacy-content">
                회사가 제공하는 서비스에 링크되어 있는 웹페이지들이 개인정보를 수집하는 행위에 대해서는 본 개인정보처리방침이 적용되지 않음을 알려 드립니다.
                </div>
                
                <br/><br/>
                
                <div className="privacy-title">
                제 11조 (개정 전 고지 의무)
                </div>
                <div className="privacy-content">
                본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항'을 통해 사전 공지를 할 것입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자의 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지합니다.<br/>
                버전 : V1.0<br/>
                공고일자 : 2024년 02월 28일<br/>
                시행일자 : 2024년 03월 08일

                </div>
            </div>
        )
    }
}

export default (Privacy)

