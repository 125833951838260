import React from 'react';
import { withRouter, Link, useParams, useLocation } from "react-router-dom";
import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Select, Icon, Radio, Carousel, Text } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { withAlert } from 'react-alert';
import QRCode from 'qrcode.react';
const BCCard = (props) => {

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    const query = useQuery();
    const code1 = query.get("code1").replace(',','');
    const code2 = query.get("code2");
    let code = '';
    let linkUrl = '';
    if (code1.length >= 5 && code2.length >= 5) {
        code = code1.substring(0,5) + code2.substring(code2.length-5, code2.length);
        linkUrl = 'https://paybooc.co.kr/app/paybooc/AppPbCard.do?exec=detail&cardGdsNo=103857&chnlCd=Mobile';
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(code).then(() => {
            alert('사원코드가 복사되었습니다.')
        }).catch((err) => {
        });
    };

    return (
        <div className="main">

            <div className="bccard-content">
                <div className="bccard-top">
                    <div className="bccard-top-left">
                        <div className="bccard-top-title">
                            냠냠박스 제휴카드 안내
                        </div>
                        <div className="bccard-top-box">
                            사원코드 : {code}
                            <img src={require('../img/bccard/copy.png').default} onClick={copyToClipboard} />
                        </div>
                    </div>
                    <div className="bccard-top-close" onClick={window.close}>
                        <img src={require('../img/common/close.png').default} />
                    </div>
                </div>
                <div className="bccard-main" style={{ backgroundImage: `url(${require('../img/bccard/bccard.png').default})` }}>
                    <div className="bccard-link" onClick={()=>window.open(linkUrl)}>
                        <img src={require('../img/bccard/link.png').default} />
                    </div>
                    <div className="bccard-qr">
                        <QRCode value={linkUrl} size={120}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default withAlert()(withRouter(BCCard));