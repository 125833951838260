export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";

export function setLogin(userinfo) {
  return {
    type: SET_LOGIN,
    userinfo: userinfo
  };
}
export function setLogout() {
  return {
    type: SET_LOGOUT,
  };
}