import React, { Component } from 'react';
import CloseIcon from '../../img/common/close.png';

class SalesAgentModal extends Component {

    render() {
        const { close } = this.props;
        return (
            <>
                <div className="flex-modal-overlay"
                     onClick={() => close()} />
                <div className="flex-wrapper">
                    <div className="flex-header">
                        <div className="flex-title" style={
                            {color: 'black'
                            }
                        }>판매대리·중개 업무위탁 증서</div>
                        <img
                            onClick={() => close()
                            }
                            src={CloseIcon
                            }
                            className="dialog-close"
                            alt="닫기"
                        />
                    </div>
                    <div>
                        <div className="dialog-inner timeDelay-inner">
                            <br/>
                            <ul>
                                <li>상호: 주식회사 커넥트9</li>
                                <li>대표자: 김정환</li>
                                <li>사업장 주소: 서울특별시 강남구 도산대로 235 5층</li>
                                <li>위탁업무: 신용카드 모집</li>
                                <li>위탁근거: 여신전문금융업법 14조의 2항</li>
                                <li>발급근거: 금융소비자보호법 시행령 제24조 2항</li>
                            </ul>
                            <br/>
                            <div style={{textAlign: 'center'}}>
                                위와 같이 업무위탁 하였음을 증명합니다.<br/>
                                <div>2024년 4월 15일</div>
                            </div>
                            <br/><br/>
                            <a href="/냠냠박스 판매대리 중개 업무위탁증서.pdf" download style={{textAlign: 'center'}}>
                                <img className="sales-agent-download" src={require("../../img/common/download.png").default} />
                                판매대리·중개 업무위탁 증서_BC카드.pdf
                            </a>
                            <div className="timeDelay-box mobile" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default SalesAgentModal;
