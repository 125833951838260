import React, { Component } from 'react';
import Main from './components/Main';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Stop from './pages/Stop';
import Download from './pages/Download';
import BCCard from './pages/BCCard';
class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          {/* <Route path="/:page" component={Stop} /> */}
          <Route path="/bccard" component={BCCard} />
          <Route path="/download" component={Download} />
          <Route path="/dn" component={Download} />
          <Route path="/:page" component={Main} />
          <Route path="/" component={Main} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
