import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Carousel, Form, Modal, Table } from "antd";
import moment from "moment";
import React from "react";
import { withAlert } from "react-alert";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { isMobile } from "react-device-detect";
import Bounce from "react-reveal/Bounce";
import RevealFade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { Link, withRouter } from "react-router-dom";
import Slider from "react-slick";
import { httpGet, httpPost, httpUrl, imageUrl } from "../api/httpClient";
const FormItem = Form.Item;

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      pagination2: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
      noticeList: [],
      endDate: "",
      startDate: "",
      title: "",
      noticeVisible: false,
      selectedNotice: "",

      jobVisible: false,
      coronaVisible: false,
      branchVisible: false,
      centerList: [],
      name: "",
      centerImage: "",
      centerVisible: false,
      selectedCenter: "",
      // modalVisible: true,
    };
    this.formRef = React.createRef();
    this.branchRef = React.createRef();
  }

  componentDidMount() {
    this.getNoticeList();
    this.getCenterList();
  }

  handleTableChange = (pagination) => {
    console.log(pagination);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState(
      {
        pagination: pager,
      },
      () => this.getNoticeList()
    );
  };

  getCenterList = () => {
    let deleted = this.state.deleted;
    let name = this.state.name;
    let pageNum = this.state.pagination.current;
    let pageSize = this.state.pagination.pageSize;
    httpGet(httpUrl.centerList, [deleted, name, pageNum, 100], {}).then(
      (res) => {
        // console.log("asdfsafasfa " + JSON.stringify(res, null, 4));
        const pagination = { ...this.state.pagination };
        pagination.current = res.data.currentPage;
        pagination.total = res.data.totalCount;
        this.setState({
          centerList: res.data.centerHomepages,
          pagination,
        });
      }
    );
  };

  getNoticeList = () => {
    let deleted = this.state.deleted;
    let endDate = this.state.endDate;
    let pageNum = this.state.pagination2.current;
    let pageSize = this.state.pagination2.pageSize;
    let startDate = this.state.startDate;
    let title = this.state.title;
    httpGet(
      httpUrl.noticeList,
      [deleted, endDate, pageNum, pageSize, startDate, title],
      {}
    ).then((res) => {
      console.log("asdfsafasfa " + JSON.stringify(res, null, 4));
      const pagination2 = { ...this.state.pagination2 };
      pagination2.current = res.data.currentPage;
      pagination2.total = res.data.totalCount;
      this.setState({
        noticeList: res.data.notices,
        pagination2,
      });
    });
  };

  OnCreateInquiry = (e) => {
    let info = JSON.stringify({
      ...this.formRef.current.getFieldsValue(),

      //추가 지정해서 보내기
      category: "B2B",
      title: "B2B 문의",
    });

    // alert(info);
    // return;
    // let {form} = this.props;

    // e.preventDefault();
    // form.validateFields((err, values) => {
    //   if (!err) {
    //     alert(JSON.stringify(values));

    //   }
    // });
    // return;
    const self = this;
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: "작성하신 내용을 전송하시겠습니까?",
      okText: "확인",
      cancelText: "취소",
      onOk() {
        httpPost(httpUrl.inquiryCreate, [], info)
          .then((response) => {
            Modal.success({
              content: (
                <div style={{ paddingLeft: "40px" }}>
                  문의가 성공적으로 접수되었습니다.
                  <br />
                  담당자가 빠른 시일내에 연락드릴 예정입니다.
                </div>
              ),
              okText: "닫기",
            });
            self.formRef.current.resetFields();
          })
          .catch((e) => {
            Modal.error({
              content: (
                <div style={{ paddingLeft: "40px" }}>
                  작성에 실패하였습니다. <br />
                  잠시 후 다시 시도해주세요.
                </div>
              ),
              okText: "닫기",
            });
          });
      },
      onCancel() { },
    });
  };

  //     closeModal = () => {
  //       this.setState({
  //           modalVisible: false
  //       })
  //   }

  render() {
    const { form } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
    };
    const columns = [
      {
        title: "번호",
        key: "idx",
        dataIndex: "idx",
        className: "column-center desk",
      },
      {
        title: "제목",
        key: "title",
        dataIndex: "title",
        className: "",
      },
      // {
      //     title: "사진",
      //     dataIndex: "",
      //     className: "table-column-center desk",
      //     render: (data, row) => {

      //         return (
      //             <div>
      //                 {row.noticeFiles.map((noticeFile) =>
      //                     <img
      //                         src={imageUrl(noticeFile.uploadFileIdx)}
      //                         style={{ width: 50, height: 50 }}
      //                         alt="새소식 사진"
      //                     />
      //                 )}
      //             </div>
      //         );
      //     },
      // },
      {
        title: "등록일",
        key: "createDate",
        dataIndex: "createDate",
        className: "column-center desk",
        render: (data) => <div>{moment(data).format("YYYY-MM-DD")}</div>,
      },
      {
        title: "등록일",
        key: "createDate",
        dataIndex: "createDate",
        className: "column-center mobile notice-date-column",
        render: (data) => <div>{data.substring(5, data.length)}</div>,
      },
    ];

    const settings = {
      className: "center",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: isMobile ? 1 : 3,
      swipeToSlide: true,
      afterChange: function (index) {
        console.log(
          `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        );
      },
    };
    return (
      <div className="main">
        {/* 다음에 다른 팝업 띄울 때 컴포넌트 이름만 바꿔 끼워넣으면 됨!!
               {this.state.modalVisible && (
                <ItaewonModal
                  visible={this.state.modalVisible}
                  closable={true}
                  maskClosable={true}
                  onClose={this.closeModal}
                  settings={settings}
                />
              )} */}
        <div className="wrapper top-wrapper">
          <div className="container top-container">
            <div className="top-logo-wrapper">
              <Link to="/">
                <img src={require("../img/common/logo.png").default} />
              </Link>
            </div>
            <div className="top-menu">
              <div className="top-menu-item desk">
                <img src={require("../img/common/icon_home.png").default} />
              </div>
              <Link to="/franchise">
                <div className="top-menu-item">가맹점</div>
              </Link>
              <Link to="/rider">
                <div className="top-menu-item">라이더</div>
              </Link>
              <Link to="/expert">
                <div className="top-menu-item">전문가상담</div>
              </Link>
            </div>
            <div className="top-title">
              <div className="top-title-01">Premium</div>
              <div className="top-title-02">Platform</div>
              <div className="top-title-03">맛있게 냠냠하면 0칼로리~</div>
              {/* <div className="top-button">
                                <div className="top-button-item" onClick={()=>this.props.alert.show('오픈예정입니다.')}>
                                    <img src={require('../img/common/btn_playstore.png').default} />
                                </div>
                                <div className="top-button-item2" onClick={()=>this.props.alert.show('오픈예정입니다.')}>
                                    <img src={require('../img/common/btn_appstore.png').default} />
                                </div>
                            </div> */}
            </div>
            <div className="top-bg">
              <img src={require("../img/common/top_bg.png").default} />
            </div>
            <div className="top-download">
              <a href="/안전 보건목표와 경영방침.pdf" download>
                <img src={require("../img/common/download.png").default} />
                안전보건목표와 경영방침
              </a>
              {/* <div>
                                <a href="/사고발생_대응_매뉴얼.pptx" download>
                                    <img src={require('../img/common/download.png').default} />
                                    사고발생_대응_매뉴얼

                                </a>
                            </div>
                            <div>
                                <a href="/전담조직.pptx" download>
                                    <img src={require('../img/common/download.png').default} />
                                    전담조직

                                </a>
                            </div> */}
            </div>
            <div className="home-go-link-wrapper">
              <AnchorLink href="#content">
                <div className="home-go-link"></div>
              </AnchorLink>
            </div>
          </div>
        </div>
        {/* <div className="sub1-bg-img" id="content">
                    <div className="wrapper sub1-wrapper">
                        <div className="container sub1-container">
                            <div className="sub1-img-circle desk">
                                <img src={require('../img/common/yellow_circle.png').default} />
                            </div>
                            <div className="sub1-img">
                            <RevealSlide bottom duration={1500} delay={0}>
                                    <img className="sub1-img-main" src={require('../img/common/sub1_item.png').default} />
                                </RevealSlide>
                            </div>
                            <div className="sub1-content">
                                <div className="sub1-text1">
                                    냠냠박스
                            </div>
                                <div className="sub1-text2">
                                    배달 “편리미엄” 플랫폼 서비스

                            </div>
                                <div className="sub1-text3">
                                    매장의 음식들을 최적의 시간에 맞춰 소비자들에게 전달하는 편리한 프리미엄 배달 서비스를 제공합니다.
                            </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* 
                <div className="wrapper sub2-wrapper">
                    <div className="container sub2-container">
                        <div className="sub2-img-wrapper">
                            <img className="sub2-img-main" src={require('../img/common/logo_circle.png').default} />
                        </div>
                        <div className="sub2-block">
                            <img src={require('../img/common/sub2_item1.png').default} />
                            <div className="sub2-title">신뢰성</div>
                            <div className="sub2-text">
                                브랜딩된 복장으로 깔끔하고 친절한
                            <br />
                            서비스를 제공하며, 24시간 고객센터
                             <br />
                            운영을 통해 신속하게 현장이슈를 대응합니다.
                            </div>
                        </div>
                        <div className="sub2-block">
                            <img src={require('../img/common/sub2_item2.png').default} />
                            <div className="sub2-title">편의성</div>
                            <div className="sub2-text">
                                빅데이터와 AI가 적용된 플랫폼을 기반으로
                            <br />
                            최적화된 배차 시스템을 운영하여 가맹점과
                            <br />
                            라이더 모두에게 편리한 서비스를 제공합니다.
                            </div>
                        </div>
                        <div className="sub2-block">
                            <img src={require('../img/common/sub2_item3.png').default} />
                            <div className="sub2-title">확장성</div>
                            <div className="sub2-text">
                                다양화 되고 있는 배달문화에 맞추어 플랫폼을
                            <br />
                            지속적으로 확장하여 소비자가 만족하는
                            <br />
                             프리미엄 배달을 추구합니다.
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="wrapper sub22-wrapper">
          <div className="center-wrapper-main">
            <div className="sub22-title">냠냠박스 지점</div>
            <div className="center-img-main">
              <div
                className="sub22-imgbox"
                onClick={() => this.setState({ centerVisible: true })}
              >
                {this.state.centerList.map((row) => {
                  return (
                    <>
                      {row.deleted === 0 && (
                        <div
                          className="imgbox1"
                          style={{
                            zIndex: 2,
                          }}
                          onClick={() => {
                            this.setState({
                              centerVisible: true,
                              selectedCenter: row.idx,
                            });
                          }}
                        >
                          {row.thumbnailIdx && (
                            <div className="sub3-thumbnail">
                              <img
                                src={imageUrl(row.thumbnailIdx)}
                                alt="지점 사진"
                              />
                            </div>
                          )}
                          <div className="sub22-text1">{row.name}</div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* 공지사항 시작*/}
        <div className="wrapper sub3-wrapper">
          <div className="notice-wrapper-main">
            <div className="sub3-title-image">
              <img src={require("../img/common/line.png").default} />
            </div>
            <div className="container sub3-container">
              <div
                className="sub3-more"
                onClick={() => this.setState({ noticeVisible: true })}
              >
                더보기
              </div>
              <div className="sub3-title">새로운 소식</div>
              <div className="sub3-text">
                냠냠박스에서 제공하는 배달정보 뉴스와 공지사항을 확인해 보세요.
              </div>
              <div className="sub3-block-wrapper">
                <Slider {...settings}>
                  {this.state.noticeList.map((row) => {
                    return (
                      <div>
                        <div
                          className="sub3-block"
                          onClick={() => {
                            this.setState({
                              noticeVisible: true,
                              selectedNotice: row.idx,
                            });
                          }}
                        >
                          {row.thumbnailIdx && (
                            <div className="sub3-thumbnail">
                              <img
                                src={imageUrl(row.thumbnailIdx)}
                                alt="새소식 사진"
                              />
                            </div>
                          )}
                          <div className="sub3-block-text">{row.content}</div>
                          <div className="sub3-block-title">{row.title}</div>
                          <div className="sub3-block-date">
                            {moment(row.createDate).format("YYYY-MM-DD")}
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        {/* 공지사항 끝 */}
        <div className="wrapper sub4-wrapper">
          <div className="container sub4-container">
            <div className="sub4-title">전문적 플랫폼</div>
            <div className="sub4-text">
              우리는 현장의 경험과 데이터를 가진 모든 배달 영역의 전문가 입니다.
            </div>
            <div className="sub4-block-wrapper">
              <Flip left duration={1500} delay={0}>
                <div
                  className="sub4-block"
                  style={{ backgroundColor: "#fbdd05" }}
                >
                  <img src={require("../img/common/sub4_item4.png").default} />
                  <div className="sub4-block-title">라이더 지원</div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    업계 최초 레벨업 제도를 통한 빠른 성장 지원
                  </div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    각종 인센티브를 제공하여 동기 부여
                  </div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    법률, 금융, 세무 교육 및 상담지원
                  </div>
                </div>
              </Flip>

              <Flip left duration={1500} delay={300}>
                <div className="sub4-block">
                  <img src={require("../img/common/sub4_item1.png").default} />
                  <div className="sub4-block-title">직영점 운영</div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    모든 라이더 들이 소속감을 가질 수 있게 전 지점 직영화
                  </div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    라이더의 휴식을 위한 라이더 스테이션 운영
                  </div>
                </div>
              </Flip>

              <Flip left duration={1500} delay={600}>
                <div className="sub4-block">
                  <img
                    style={{ marginTop: 20 }}
                    src={require("../img/common/sub4_item2.png").default}
                  />
                  <div className="sub4-block-title">서비스 관리</div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    체계적인 관리 시스템을 갖추어 전문성 강화
                  </div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    안전 교육, 서비스 교육을 통한 서비스 품질 향상
                  </div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    모든 복장, 물품의 브랜딩을 통해 통일된 이미지를 형성
                  </div>
                </div>
              </Flip>

              <Flip left duration={1500} delay={900}>
                <div className="sub4-block">
                  <img src={require("../img/common/sub4_item3.png").default} />
                  <div className="sub4-block-title">지역연구</div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    자체 맵 개발(특허출원 예정)을 통한 효율적 네비 시스템 구축
                  </div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    가맹점 조리시간에 따른 배차 최적화
                  </div>
                  <div className="sub4-block-text">
                    <div className="main-dots" />
                    지역 특성에 맞는 배달 동선 연구
                  </div>
                </div>
              </Flip>
            </div>
          </div>
        </div>

        {/* <div className="wrapper sub5-wrapper">
                    <div className="container sub5-container">
                        <div className="sub5-title">신뢰의 플랫폼</div>
                        <div className="sub5-text">우리는 서로 믿을 수 있습니다.</div>

                        <div className="sub5-subtitle">브랜딩</div>
                        <div className="sub5-subtext">모든 복장, 물품의 브랜딩을 통해 통일된 이미지를 형성하고, 교육을 통해 서비스 퀄리티를 향상시킵니다.</div>

                        <div className="sub5-subtitle">IT (Information Technology)</div>
                        <div className="sub5-subtext">빅데이터를 활용하여 최적의 배달정보를 공유하며 기존 배달 시스템의 비효율성을 개선하였습니다.</div>

                        <div className="sub5-subtitle">소비자 만족</div>
                        <div className="sub5-subtext">정성을 다하는 친절한 고객 응대와 격식 있는 서비스를 제공합니다. </div>
                    </div>
                </div> */}

        <div className="wrapper sub6-wrapper">
          <div className="sub6-wrapper2">
            <div className="container sub6-container">
              <div className="sub6-title">편리한 플랫폼</div>
              <div className="sub6-text">이거 되게 쉬운데요</div>

              <div className="sub6-info">
                <div className="sub6-info-image">
                  <Bounce duration={1500} delay={0}>
                    <img
                      src={
                        require("../img/common/sub6_item_title1.png").default
                      }
                    />
                  </Bounce>
                </div>
                <div className="sub6-info-image2 mobile">
                  <Bounce duration={1500} delay={0}>
                    <img
                      src={
                        require("../img/common/sub6_item_title2_m.png").default
                      }
                    />
                  </Bounce>
                </div>
                <div className="sub6-explain">
                  <div className="sub6-subtitle">간편화</div>
                  <div className="sub6-subtext">
                    <div className="main-dots2" />
                    최소한의 터치로 픽업요청과 콜수락
                  </div>
                  <div className="sub6-subtitle">다양성</div>
                  <div className="sub6-subtext">
                    <div className="main-dots2" />
                    PC 및 모바일에서 모두 사용 가능하게
                  </div>
                  <div className="sub6-subtext">
                    <div className="main-dots2" />
                    여러 디바이스에 최적화
                  </div>
                  <div className="sub6-subtitle">커스텀화</div>
                  <div className="sub6-subtext">
                    <div className="main-dots2" />
                    본인 취향에 따라 여러가지 메뉴를 설정할 수 있게
                  </div>
                  <div className="sub6-subtitle">지능화</div>
                  <div className="sub6-subtext">
                    <div className="main-dots2" />
                    AI 자동배차 시스템 개발로 효율성의 극대화
                  </div>
                  <div className="sub6-subtext">
                    <div className="main-dots2" />
                    빅데이터 활용으로 최적의 배달정보 공유
                  </div>
                  <div className="sub6-subtext">
                    <div className="main-dots2" />
                    지속적인 기존 배달 시스템의 비효율성 개선
                  </div>
                </div>
                <div className="sub6-info-image2 desk">
                  <Bounce duration={1500} delay={500}>
                    <img
                      src={
                        require("../img/common/sub6_item_title2.png").default
                      }
                    />
                  </Bounce>
                </div>
              </div>

              <div className="sub6-title2">확장의 플랫폼</div>
              <div className="sub6-text">
                배달 사업은 아직 발견하지 못한 것이 많은 신대륙
              </div>

              <div className="sub6-block-wrapper">
                {/* <div className="sub6-block">
                                    <img src={require('../img/common/sub6_item1.png').default} />
                                    <div className="sub6-block-title">온라인 마트(편의점)로 확장</div>
                                </div> */}
                <RevealFade right duration={1500} delay={0}>
                  <div className="sub6-block">
                    <img
                      src={require("../img/common/sub6_item2.png").default}
                    />
                    <div className="sub6-block-title">
                      새로운 플랫폼과의 결합
                    </div>
                  </div>
                </RevealFade>
                <RevealFade right duration={1500} delay={500}>
                  <div className="sub6-block">
                    <img
                      src={require("../img/common/sub6_item3.png").default}
                    />
                    <div className="sub6-block-title">B2B 서비스 확장</div>
                  </div>
                </RevealFade>
                <RevealFade right duration={1500} delay={1000}>
                  <div className="sub6-block">
                    <img
                      src={require("../img/common/sub6_item4.png").default}
                    />
                    <div className="sub6-block-title">
                      소형화물 배달서비스 확장
                    </div>
                  </div>
                </RevealFade>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="wrapper">
                    <div className="container sub7-container">
                        <div className="sub7-title">냠냠박스 직영점</div>
                        <div className="sub7-text">· 김포1지점: 김포시 양촌읍 석모리 341</div>
                    </div>
                </div>
                <div className="wrapper sub7-wrapper">
                    <div className="sub7-wrapper2">

                    </div>
                </div> */}

        {this.state.jobVisible && (
          <div
            className="modal-container "
            onClick={() => {
              this.setState({ jobVisible: false });
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={require("../img/common/job.jpg").default} />
          </div>
        )}
        {this.state.coronaVisible && (
          <div
            className="modal-container "
            onClick={() => {
              this.setState({ coronaVisible: false });
            }}
            style={{ cursor: "pointer" }}
          >
            <img src={require("../img/common/corona.jpg").default} />
          </div>
        )}
        {this.state.branchVisible && (
          <div
            className="modal-container"
            onClick={(e) => {
              if (e.target !== e.currentTarget) return;
              this.setState({ branchVisible: false });
            }}
          >
            <div
              className="modal-carousel-container"
              onClick={() => {
                this.branchRef.current.next();
              }}
            >
              <Carousel ref={this.branchRef}>
                <div>
                  <img
                    src={require("../img/common/job1.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src={require("../img/common/job2.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src={require("../img/common/job3.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src={require("../img/common/job4.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src={require("../img/common/job5.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src={require("../img/common/job6.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src={require("../img/common/job7.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  <img
                    src={require("../img/common/job8.png").default}
                    style={{ width: "100%" }}
                  />
                </div>
              </Carousel>
            </div>
          </div>
        )}

        {this.state.centerVisible && this.state.selectedCenter !== "" && (
          <div
            className="modal-container"
            onClick={(e) => {
              console.log(e.target, e.currentTarget);
              if (e.target !== e.currentTarget) return;
              this.setState({ centerVisible: false, selectedCenter: "" });
            }}
          >
            <div className="place-container">
              <div className="place-title">
                <div className="notice-title-main">
                  {
                    this.state.centerList.find(
                      (x) => x.idx === this.state.selectedCenter
                    ).name
                  }
                </div>
              </div>
              <div className="notice-detail">
                {this.state.centerList.find(
                  (x) => x.idx == this.state.selectedCenter
                ).noticeFiles && (
                    <div className="notice-detail-bottom">
                      {this.state.centerList
                        .find((x) => x.idx == this.state.selectedCenter)
                        .noticeFiles.map((noticeFile) => {
                          return (
                            <div>
                              <img
                                src={imageUrl(noticeFile.uploadFileIdx)}
                                alt="지점 사진"
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
              </div>
              <div className="notice-bottom">
                <div className="sub22-bottom-text">
                  <pre className="sub22-bottom-text-pre">
                    {
                      this.state.centerList.find(
                        (x) => x.idx === this.state.selectedCenter
                      ).addr
                    }
                    <br />
                    {
                      this.state.centerList.find(
                        (x) => x.idx === this.state.selectedCenter
                      ).tel
                    }
                  </pre>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* 공지사항 시작 */}

        {this.state.noticeVisible && this.state.selectedNotice == "" && (
          <div
            className="modal-container "
            onClick={(e) => {
              if (e.target !== e.currentTarget) return;
              this.setState({ noticeVisible: false, selectedNotice: "" });
            }}
          >
            <div className="notice-container">
              <div className="notice-title" />
              <div className="notice-table">
                <Table
                  rowKey={(record) => record.idx}
                  // rowClassName={(record) => (record.status === 'COMPLETE' ? "table-disabled" : "")}
                  dataSource={this.state.noticeList}
                  columns={columns}
                  pagination={this.state.pagination}
                  onChange={this.handleTableChange}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        this.setState({ selectedNotice: record.idx });
                      },
                    };
                  }}
                />
              </div>
              <div></div>
            </div>
          </div>
        )}
        {this.state.noticeVisible && this.state.selectedNotice != "" && (
          <div
            className="modal-container "
            onClick={(e) => {
              if (e.target !== e.currentTarget) return;
              this.setState({ noticeVisible: false, selectedNotice: "" });
            }}
          >
            <div className="notice-container">
              <div className="notice-title">
                <div className="notice-title-main">
                  {
                    this.state.noticeList.find(
                      (x) => x.idx == this.state.selectedNotice
                    ).title
                  }
                </div>
                <div className="notice-title-date">
                  · 등록일:{" "}
                  {
                    this.state.noticeList.find(
                      (x) => x.idx == this.state.selectedNotice
                    ).createDate
                  }
                </div>
              </div>
              <div className="notice-detail">
                {/* {this.state.noticeList.find(x=>x.idx == this.state.selectedNotice).topCarousel && (
                                    <div className="notice-detail-top"> 
                                        <Carousel>
                                        {this.state.noticeList.find(x=>x.idx == this.state.selectedNotice).topCarousel.map(row => {
                                            return (
                                                <div>
                                                    <img src={row} style={{width: '50%'}}/>
                                                </div>
                                            )
                                        })}
                                        </Carousel>
                                    </div>
                                )} */}
                <div>
                  <pre className="notice-bottom-text-pre">
                    {this.state.noticeList
                      .find((x) => x.idx == this.state.selectedNotice)
                      .content.split("\r\n")
                      .map((value) => {
                        return (
                          <>
                            {value}
                            <br />
                          </>
                        );
                      })}
                  </pre>
                </div>
                {this.state.noticeList.find(
                  (x) => x.idx == this.state.selectedNotice
                ).noticeFiles && (
                    <div className="notice-detail-bottom">
                      {this.state.noticeList
                        .find((x) => x.idx == this.state.selectedNotice)
                        .noticeFiles.map((noticeFile) => {
                          return (
                            <div>
                              <img
                                src={imageUrl(noticeFile.uploadFileIdx)}
                                alt="새소식 사진"
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
              </div>
              <div className="notice-bottom">
                <div className="notice-bottom-left">
                  <div
                    className="button-circle-gray"
                    onClick={() => {
                      let idx = this.state.noticeList.findIndex(
                        (x) => x.idx == this.state.selectedNotice
                      );
                      if (idx > 0) idx--;
                      this.setState({
                        selectedNotice: this.state.noticeList[idx].idx,
                      });
                    }}
                  >
                    <span className="desk">이전글</span>
                    <span className="mobile">&lt;</span>
                  </div>
                  <div
                    className="button-circle-gray"
                    onClick={() => {
                      let idx = this.state.noticeList.findIndex(
                        (x) => x.idx == this.state.selectedNotice
                      );
                      if (idx < this.state.noticeList.length - 1) idx++;
                      this.setState({
                        selectedNotice: this.state.noticeList[idx].idx,
                      });
                    }}
                  >
                    <span className="desk">다음글</span>
                    <span className="mobile">&gt;</span>
                  </div>
                </div>
                <div className="notice-bottom-right">
                  <div
                    className="button-circle-black"
                    onClick={() => {
                      this.setState({ selectedNotice: "" });
                    }}
                  >
                    <span className="desk">목록보기</span>
                    <span className="mobile">목록</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withAlert()(withRouter(Intro));
