import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Intro from '../pages/Intro';
import Franchise from '../pages/Franchise';
import Rider from '../pages/Rider';
import ScrollToTop from './util/ScrollToTop';
import Expert from '../pages/Expert';
import Guide from '../pages/Guide';
import Privacy from '../pages/Privacy';
import BCCard from '../pages/BCCard';

export default class Content extends Component {

    render() {
        return (
            <ScrollToTop>
                <Switch>
                    <Route exact path="/" component={Intro} />
                    <Route exact path="/Franchise" component={Franchise} />
                    <Route exact path="/Rider" component={Rider} />
                    <Route exact path="/expert" component={Expert} />
                    <Route exact path="/guide" component={Guide} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Redirect to="/" />
                </Switch>
            </ScrollToTop>
        );
    }
}
