export default {
  appName: 'nnbox',

  // //local
  // serverProtocol: "http",
  // serverIp: "localhost",
  // serverPort: "9002",

  //stage
  // serverProtocol: "https",
  // serverIp: "nnstage.nnbox.net",
  // serverPort: "9002",

  //prod
  serverProtocol: "https",
  serverIp: "www.nyamnyambox.com",
  serverPort: "8443",
  
  clientIp: "localhost",
  clientPort: "3000",
  serverContext: "",
};
