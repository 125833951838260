import { combineReducers } from "redux";
import { SET_LOGIN, SET_LOGOUT } from "../actions";
import { reactLocalStorage } from "reactjs-localstorage";

const initLoginState = {
  isLogin: false,
  userInfo: {}
};

const loginReducer = (state = initLoginState, action) => {
  switch (action.type) {
    case SET_LOGIN:
      reactLocalStorage.setObject("nnbox#userinfo", action.userinfo);
      return Object.assign(
        {},
        {
          isLogin: true,
          userinfo: action.userinfo
        }
      );
    case SET_LOGOUT:
      reactLocalStorage.remove("nnbox#userinfo");
      return Object.assign(
        {},
        {
          isLogin: false,
          userinfo: {}
        }
      );
    default:
      return state;
  }
};

const reducer = combineReducers({ loginReducer });

export default reducer;
