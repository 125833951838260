import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Carousel, Form, Modal, Radio, Select } from "antd";
import React from 'react';
import { withAlert } from 'react-alert';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Flip from 'react-reveal/Flip';
import { Link, withRouter } from "react-router-dom";
import { httpPost, httpUrl } from '../api/httpClient';
const Option = Select.Option;
const FormItem = Form.Item;

class Rider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.formRef = React.createRef();
        this.rider1Ref = React.createRef();
        this.rider2Ref = React.createRef();
        this.rider3Ref = React.createRef();
        this.rider4Ref = React.createRef();
    }

    // onFinish = (e) => {
    //     alert(JSON.stringify(this.formRef.current.getFieldsValue()));
    // }

    OnCreateInquiry = (e) => {
        let info = JSON.stringify({
            ...this.formRef.current.getFieldsValue(),

            //추가 지정해서 보내기
            category: 'RIDE',
            title: '라이더 문의',
            content: '주소:' + this.formRef.current.getFieldValue('address')
                + ', 희망지역:' + this.formRef.current.getFieldValue('area')
                + ', 생년월일:' + this.formRef.current.getFieldValue('birth')
                + ', 라이더 경력:' + this.formRef.current.getFieldValue('year')
                + ', 성별:' + this.formRef.current.getFieldValue('gender')
                + ', 내용:' + this.formRef.current.getFieldValue('content')
        });

        // alert(info);
        // return;
        // let {form} = this.props;

        // e.preventDefault();
        // form.validateFields((err, values) => {
        //   if (!err) {
        //     alert(JSON.stringify(values));

        //   }
        // });
        // return;
        const self = this;
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: "작성하신 내용을 전송하시겠습니까?",
            okText: '확인',
            cancelText: '취소',
            onOk() {
                httpPost(httpUrl.inquiryCreate, [], info).then(response => {
                    Modal.success({
                        content: (
                            <div style={{ paddingLeft: '40px' }}>
                                문의가 성공적으로 접수되었습니다.<br />
                                담당자가 빠른 시일내에 연락드릴 예정입니다.
                            </div>
                        ),
                        okText: '닫기'
                    });
                    self.formRef.current.resetFields();
                }).catch(e => {
                    Modal.error({
                        content: (
                            <div style={{ paddingLeft: '40px' }}>
                                작성에 실패하였습니다. <br />잠시 후 다시 시도해주세요.
                            </div>
                        ),
                        okText: '닫기'
                    });
                })
            },
            onCancel() {
            },
        });

    }

    render() {
        const { form } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 17 }
            }
        };
        return (
            <div className="main">
                <div className="wrapper rider-top-wrapper">
                    <div className="container rider-top-container">
                        <div className="top-logo-wrapper">
                            <Link to='/'>
                                <img src={require('../img/common/logo.png').default} />
                            </Link>
                        </div>
                        <div className="rider-top-menu">
                            <div className="rider-top-menu-item desk">
                                <Link to='/'>
                                    <img src={require('../img/common/icon_home.png').default} />
                                </Link>
                            </div>

                            <Link to='/franchise'>
                                <div className="rider-top-menu-item2">가맹점</div>
                            </Link>

                            <div className="rider-top-menu-item3">라이더</div>

                            <Link to='/expert'>
                                <div className="top-menu-item">전문가상담</div>
                            </Link>
                        </div>
                        {/* <div className="rider-top-title-01">new</div> */}
                        <div className="rider-top-title-01">냠냠박스와 함께</div>
                        <div className="rider-top-title-02">라이더의 행복한 꿈!</div>
                        <div className="rider-top-title-03">
                            냠냠박스는 함께하는 가족이라는 생각으로
                            <br />
                            모든 라이더에게 성장할 수 있는 비전을 제공합니다.
                        </div>
                        {/* <div className="rider-top-button">
                            <div className="rider-top-button-item" onClick={() => this.props.alert.show('오픈예정입니다.')}>
                                <img src={require('../img/common/btn_playstore.png').default} />
                            </div>
                            <div className="rider-top-button-item2" onClick={() => this.props.alert.show('오픈예정입니다.')}>
                                <img src={require('../img/common/btn_appstore.png').default} />
                            </div>
                        </div> */}
                        <div className="top-bg3">
                            <div>
                                <img src={require('../img/common/rider/rider_top_bg1.png').default} />
                            </div>
                            {/* <div>
                            <img src={require('../img/common/rider/rider_top_bg2.png').default} />
                            </div>
                            <div>
                            <img src={require('../img/common/rider/rider_top_bg3.png').default} />
                            </div> */}
                        </div>
                        <div className="home-go-link-wrapper">
                            <AnchorLink href='#content'>
                                <div className="home-go-link"></div>
                            </AnchorLink>
                        </div>
                    </div>
                </div>

                <div className="rider-staff-container">
                    <div className="rider-staff-container2">
                        <div className="rider-staff-content">
                            {/* <div className="rider-staff-title">
                                업계최초 라이더 정직원 제도
                            </div> */}
                            <div className="rider-staff-title desk">
                                내 일터의 자부심이 생기는 냠냠박스 라이더의 세상은 <br />누구보다 안정됩니다.
                            </div>
                            <div className="rider-staff-title mobile">
                                내 일터의 자부심이 생기는 냠냠박스 <br /> 라이더의 세상은 누구보다 안정됩니다.
                            </div>
                            {/* <div className="rider-staff-image">
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff1.png').default} />
                                    <div className="rider-staff-image-text">
                                        <div>
                                            리스비 지원
                                        </div>
                                    </div>
                                </div>
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff2.png').default} />
                                    <div className="rider-staff-image-text">
                                        <div>
                                            4대보험
                                        </div>
                                    </div>
                                </div>
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff3.png').default} />
                                    <div className="rider-staff-image-text">
                                        <div>
                                            퇴직금
                                        </div>
                                    </div>
                                </div>
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff4.png').default} />
                                    <div className="rider-staff-image-text desk">
                                        <div>
                                            업계 최초<br /> '레벨업 보상제' 등<br />
                                            다양한 인센티브
                                        </div>
                                    </div>
                                    <div className="rider-staff-image-text-long mobile">
                                        <div>
                                            업계 최초<br /> '레벨업 보상제' 등<br />
                                            다양한 인센티브
                                        </div>
                                    </div>
                                </div>
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff5.png').default} />
                                    <div className="rider-staff-image-text">
                                        <div>
                                            긴급자금 지원
                                        </div>
                                    </div>
                                </div>
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff6.png').default} />
                                    <div className="rider-staff-image-text">
                                        <div>
                                            경조사 지원금
                                        </div>
                                    </div>
                                </div>
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff7.png').default} />
                                    <div className="rider-staff-image-text">
                                        <div>
                                            대학병원 수준의<br />
                                            종합건강검진
                                        </div>
                                    </div>
                                </div>
                                <div className="rider-staff-image-wrapper">
                                    <img src={require('../img/rider/rider_staff8.png').default} />
                                    <div className="rider-staff-image-text">
                                        <div>
                                            신용회복 지원
                                        </div>
                                    </div>
                                </div>

                            </div> */}
                            {/* <div className="rider-staff-text1 desk">
                                냠냠박스는 라이더분들이 일하면서 보람과<br />
                                혜택을 늘리기 위해 업계 최초로 레벨업 제도를 시행하고 있습니다.
                            </div>
                            <div className="rider-staff-text1 mobile">
                                냠냠박스는 라이더분들이 일하면서<br />
                                보람과 혜택을 늘리기 위해 업계 최초로<br /> 레벨업 제도를 시행하고 있습니다.
                            </div> */}
                        </div>

                    </div>
                </div>

                <div className="rider1-bg-img" id="content">
                    <div className="wrapper rider1-wrapper">
                        <div className="container rider1-container">
                            <div className="rider1-img" onClick={() => this.rider1Ref.current.next()}>
                                <Carousel ref={this.rider1Ref}>
                                    <div>
                                        <img src={require('../img/common/rider/sub1_bg1.png').default} />
                                    </div>
                                    <div>
                                        <img src={require('../img/common/rider/sub1_bg2.png').default} />
                                    </div>
                                    <div>
                                        <img src={require('../img/common/rider/sub1_bg3.png').default} />
                                    </div>
                                    <div>
                                        <img src={require('../img/common/rider/sub1_bg4.png').default} />
                                    </div>
                                    <div>
                                        <img src={require('../img/common/rider/sub1_bg5.png').default} />
                                    </div>
                                </Carousel>
                            </div>
                            <div className="rider1-content">
                                <div className="rider1-text1">
                                    라이더 케어(복지) 시스템이 <br /> 일과를 편안하게
                                </div>
                                {/* <div className="rider1-text2">
                                    동종업계 최고의 근무환경과 성과급으로 보상해 드립니다.
                                </div> */}
                                <div className="rider1-block">
                                    <div className="rider1-block-text">
                                        <div className="main-dots2" />
                                        라이더 개인 락커,  PC룸 및 편안한 휴식공간을 드려요.
                                    </div>
                                    <div className="rider1-block-text">
                                        <div className="main-dots2" />
                                        최저 배달 수수료는 당연히 최고 조건이겠죠.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="container rider2-container">
                        <div className="rider2-img" onClick={() => this.rider2Ref.current.next()}>
                            <Carousel ref={this.rider2Ref} >
                                <div>
                                    <img src={require('../img/common/rider/sub2_bg01.png').default} />
                                </div>
                            </Carousel>
                        </div>
                        <div className="rider2-content">
                            <div className="rider2-text1">
                                초보 라이더여 어서 오세요
                            </div>
                            {/* <div className="rider2-text2">
                                즐겁고 행복한 삶을 희망하는 초보 라이더 환영합니다.
                            </div> */}
                            <div className="rider2-text3">
                                <div className="rider2-block">
                                    <div className="rider2-block-text desk">
                                        <div className="main-dots2" />
                                        주행 전, 안전 주행 교육 시스템으로 초보자도 쉽게 시작할 수 있어요.
                                    </div>
                                    <div className="rider2-block-text mobile">
                                        <div className="main-dots2" />
                                        주행 전, 안전 주행 교육 시스템으로 초보자도 쉽게 <br />시작할 수 있어요.
                                    </div>

                                    <div className="rider2-block-text desk">
                                        <div className="main-dots2" />
                                        지금 당장 바이크와 장비가 없어도 라이더가 될 수 있어요.<br />
                                        냠냠박스는  바이크 리스업과 전용몰을 운영하고 있기 때문이죠.<br />
                                    </div>
                                    <div className="rider2-block-text mobile">
                                        <div className="main-dots2" />
                                        지금 당장 바이크와 장비가 없어도 라이더가 될 수 있어요.
                                        냠냠박스는  바이크 리스업과 전용몰을 운영하고 있기 때문이죠.<br />
                                    </div>
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        누구나 사용하기 쉬운 전용 앱으로 시작해 보세요.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="wrapper">
                    <div className="container rider2-container">
                        <div className="rider2-img">
                            <div>
                                <img src={require('../img/common/rider/sub2_bg13.png').default} />
                            </div>
                        </div>
                        <div className="rider2-content">
                            <div className="rider2-text1">
                                금융지원 서비스로 안정된 시작을
                            </div>
                            <div className="rider2-text2">
                                즐겁고 행복한 삶을 희망하는 초보 라이더 환영합니다.
                            </div>
                            <div className="rider2-text3">
                                <div className="rider2-block">
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        라이더분들을 위해 지원금 제도가 있습니다.
                                    </div>
                                    <div className="rider2-block-text desk">
                                        <div className="main-dots2" />
                                        냠냠박스의 라이더는 100만원에서 최대 500만원 지원금으로 긴급자금<br /> 사용이 가능해요.
                                    </div>
                                    <div className="rider2-block-text mobile">
                                        <div className="main-dots2" />
                                        냠냠박스의 라이더는 100만원에서 최대 500만원 지원금으로 긴급자금 사용이 가능해요.
                                    </div>
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        자세한 사항은 전문가 상담을 통해 가능합니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="wrapper">
                    <div className="container rider2-container">
                        <div className="rider2-img">
                            <div>
                                <img src={require('../img/common/rider/sub2_bg12.png').default} />
                            </div>
                        </div>
                        <div className="rider2-content">
                            <div className="rider2-text1">
                                신용회복을 생각중이신가요??
                            </div>
                             <div className="rider2-text2">
                                즐겁고 행복한 삶을 희망하는 초보 라이더 환영합니다.
                            </div>
                            <div className="rider2-text3">
                                <div className="rider2-block">
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        냠냠박스에서 라이더 및 가맹점주님들의 신용회복을 도와드리고자
                                        "법무법인 인"과 업무협약을 맺어 무료로 신용회복 상담을 해드립니다.
                                    </div>
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        신용회복 법무비용을 본사에서 100만원 지원해 드립니다.

                                    </div>
                                    <br />
                                    <div className="franchise2-block-btn">
                                        <div className="btn-primary" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/expert',
                                                hash: '#content',
                                                state: { category: 2 }
                                            })
                                        }}>
                                            바로가기
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="wrapper">
                    <div className="container rider2-container">
                        <div className="rider2-img" onClick={() => this.rider4Ref.current.next()}>
                            <Carousel ref={this.rider4Ref} >
                                <div>
                                    <img src={require('../img/common/rider/sub5_bg02.jpg').default} />
                                </div>
                            </Carousel>
                        </div>
                        <div className="rider2-content">
                            <div className="rider2-text1">
                                레벨-업 제도로 더욱 빠른 성장을
                            </div>
                            <div className="rider2-text2">
                                즐겁고 행복한 삶을 희망하는 초보 라이더 환영합니다.
                            </div>
                            <div className="rider2-text3">
                                <div className="rider2-block">
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        배달건수가 증가한다면 배달수수료가 최대 40%나 인하돼요.
                                    </div>
                                    <div className="rider2-block-text desk">
                                        <div className="main-dots2" />
                                         레벨 6 이상 달성 시 '라이더 상해보험'에 무상으로 가입하여<br/> 혹시 모를 사고에 대비 하고 있어요.
                                    </div>
                                    <div className="rider2-block-text mobile">
                                        <div className="main-dots2" />
                                         레벨 6 이상 달성 시 '라이더 상해보험'에 무상으로 가입하여 혹시 모를 사고에 대비 하고 있어요.
                                    </div>
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        이 레벨-업 제도는 배달대행 업계 최초로 냠냠박스가 시행하고 있어요.
                                    </div>
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        사용하기 쉬운 냠냠박스 전용 앱은 안드로이드, 아이폰 모두 가능합니다.
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div> */}

                <div className="wrapper">
                    <div className="container rider2-container-end">
                        <div className="rider2-img" onClick={() => this.rider4Ref.current.next()}>
                            <Carousel ref={this.rider4Ref} >
                                <div>
                                    <img src={require('../img/common/rider/sub6_bg04.png').default} />
                                </div>
                                <div>
                                    <img src={require('../img/common/rider/sub6_bg05.png').default} />
                                </div>
                                <div>
                                    <img src={require('../img/common/rider/sub6_bg06.png').default} />
                                </div>
                            </Carousel>
                        </div>
                        <div className="rider2-content">
                            <div className="rider2-text1">
                                정비센터로 저렴하게
                            </div>
                            <div className="rider2-text3">
                                <div className="rider2-block">
                                    <div className="rider2-block-text desk">
                                        <div className="main-dots2" />
                                        냠냠박스는 자체 정비센터를 운영하여 라이더분들의 부담을 최소화할 수 있게<br />
                                        최소 비용으로 바이크 수리가 가능해요.
                                    </div>
                                    <div className="rider2-block-text mobile" >
                                        <div className="main-dots2" />
                                        냠냠박스는 자체 정비센터를 운영하여 라이더분들의 부담을 최소화할 수 있게
                                        최소 비용으로 바이크 수리가 가능해요.
                                    </div>
                                </div>
                            </div>
                            <div className="rider2-img2">
                                <img src={require('../img/common/rider/line2.png').default} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper rider3-wrapper">
                    <div className="container rider3-container">
                        <div className="rider3-title">라이더 전용 Mall</div>
                        <div className="rider3-mall-link" onClick={() => this.props.alert.show('오픈예정입니다.')}>몰 바로가기</div>
                        <div className="rider3-text">
                            냠냠박스 브랜딩이 된 각종 물품들을 라이더 전용 몰을 통해 구입할 수 있습니다.<br />
                            (입사 시 일부 품목은 무상대여 해 드립니다.)
                        </div>
                        <div className="rider3-block-wrapper">
                            <Flip left duration={1500} delay={0}>
                                <div className="rider3-block">
                                    <img src={require('../img/common/rider/sub3_item1.png').default} />
                                    <div className="rider3-block-title">라이더 헬멧</div>
                                    <div className="rider3-block-text">
                                        <div className="main-dots" />
                                        성인 표준사이즈의 헬멧으로 통풍과 땀흡수가 잘되는 기능성과 강한 외력에도 견딜 수 있는 안전성을 제공합니다.
                                    </div>
                                    {/* <div className="rider3-block-text2">
                                    230,000 원
                                </div> */}
                                </div>
                            </Flip>
                            <Flip left duration={1500} delay={500}>
                                <div className="rider3-block">
                                    <img src={require('../img/common/rider/sub3_item2.png').default} />
                                    <div className="rider3-block-title">라이더 조끼</div>
                                    <div className="rider3-block-text">
                                        <div className="main-dots" />
                                        고휘도 반사띠를 부착하여 야간에도 라이더의 안전을 도와주는 조끼로서 통기성이 뛰어나며 스마트폰 수납이 용이합니다.
                                    </div>
                                    {/* <div className="rider3-block-text2">
                                    230,000 원
                                </div> */}
                                </div>
                            </Flip>
                            <Flip left duration={1500} delay={1000}>
                                <div className="rider3-block">
                                    <img src={require('../img/common/rider/sub3_item3.png').default} />
                                    <div className="rider3-block-title">라이더 유니폼</div>
                                    <div className="rider3-block-text">
                                        <div className="main-dots" />
                                        통기성이 뛰어난 소재가 땀을 빠르게 발산하며 냠냠박스 브랜드의 심미성을 극대화한 유니폼 입니다.
                                    </div>
                                    {/* <div className="rider3-block-text2">
                                    230,000 원
                                </div> */}
                                </div>
                            </Flip>
                        </div>
                    </div>
                </div>

                <div className="wrapper rider4-wrapper" id="asking">
                    <div className="container rider4-container">
                        <div className="rider4-left">
                            <div className="rider4-title">라이더 지원</div>
                            <div className="rider4-text">
                                냠냠박스는 라이더와 함께 성장하기를 바라며 지역 배달문화에
                                <br />
                                앞장서 나가겠습니다.
                            </div>
                        </div>
                        <div className="rider4-right">
                            <Form ref={this.formRef} {...formItemLayout} onFinish={this.OnCreateInquiry}>
                                <div className="rider4-right-input">
                                    <div className="rider4-right-input-title">
                                        이름
                                    </div>
                                    <FormItem
                                        name="name"
                                        rules={[{ required: true, message: '이름을 입력해주세요' }]}>
                                        {/* <Input className="rider4-input-box" /> */}
                                        <input className="rider4-input-box" />
                                    </FormItem>
                                    {/* <input type="text" id="rider4-input"
                                /> */}
                                </div>

                                <div className="rider4-right-input">
                                    <div className="rider4-right-input-block">
                                        <div className="rider4-right-input-title2">
                                            생년월일
                                        </div>
                                        <FormItem
                                            name="birth"
                                            rules={[{ required: true, message: '생년월일 입력해주세요' }]}
                                        >
                                            <input className="rider4-input-box2" />
                                        </FormItem>
                                        {/* <input type="text" id="rider4-input2"/> */}
                                    </div>
                                    <div className="rider4-right-input-block2">
                                        <div className="rider4-right-input-title2">
                                            연락처
                                        </div>
                                        <FormItem
                                            name="phone"
                                            rules={[{ required: true, message: '연락처를 입력해주세요' }]}
                                        >
                                            <input className="rider4-input-box2" />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="rider4-right-input">
                                    <div className="rider4-right-input-block">
                                        <div className="rider4-right-input-title2">
                                            주소
                                        </div>
                                        <FormItem
                                            name="address"
                                            rules={[{ required: true, message: "주소를 입력해주세요" }]}
                                        >
                                            <input className="rider4-input-box2" />
                                        </FormItem>
                                    </div>
                                    <div className="rider4-right-input-block2">
                                        <div className="rider4-right-input-title2">
                                            성별
                                        </div>
                                        <FormItem
                                            name="gender"
                                            rules={[{ required: true, message: '성별을 선택해주세요' }]}
                                            initialValue='man'
                                        >

                                            <Radio.Group className="rider4-input-radio">
                                                <Radio value={'man'}>남</Radio>
                                                <Radio value={'woman'}>여</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="rider4-right-input">
                                    <div className="rider4-right-input-block">
                                        <div className="rider4-right-input-title2">
                                            근무 희망지역
                                        </div>
                                        <FormItem
                                            name="area"
                                            rules={[{ required: true, message: '희망지역을 선택해주세요' }]}
                                            initialValue='김포'>
                                            <select className="rider4-input-select">
                                                <option key={1} value={'김포'}>김포</option>
                                                <option key={2} value={'인천'}>인천</option>
                                                <option key={3} value={'시흥'}>시흥</option>
                                                <option key={4} value={'고양'}>고양</option>
                                                <option key={5} value={'성남'}>성남</option>
                                                <option key={6} value={'기타'}>기타</option>
                                            </select>
                                        </FormItem>
                                    </div>
                                    <div className="rider4-right-input-block2">
                                        <div className="rider4-right-input-title2">
                                            라이더 경력
                                        </div>
                                        <FormItem
                                            name="year"
                                            rules={[{ required: true, message: "주중/주말 배달건수를 입력해주세요" }]}
                                        >
                                            <input className="rider4-input-box2" />
                                        </FormItem>
                                    </div>
                                </div>
                                <div>
                                    <div className="rider4-right-input-title2">
                                        지원내용
                                    </div>
                                    <FormItem
                                        name="content"
                                        rules={[{ required: true, message: "지원내용을 입력해주세요" }]}
                                    >
                                        <textarea className="rider4-input-box3" />
                                    </FormItem>
                                </div>

                                <div className="rider4-right-input-check">
                                    {/* <div className="rider4-right-input-img">
                                        <img className="rider4-check-img" src={require('../img/common/rider/input_check.png').default} />
                                    약관동의
                                </div> */}
                                    <button className="rider4-right-input-button" type="primary" htmlType="submit">
                                        보내기
                                    </button>
                                </div>
                            </Form>
                        </div>


                    </div>

                </div>
            </div>
        )
    }
}

export default withAlert()(withRouter(Rider));
