import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { httpPost, httpUrl } from '../api/httpClient';
import { Form, Modal, Input, Affix, DatePicker, Descriptions, Upload, Button, Select, Icon, Radio, Carousel, Text, Divider } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { withAlert } from 'react-alert';
import RevealSlide from 'react-reveal/Slide';
import RevealFade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
import { HashLink } from 'react-router-hash-link';
import CardModal from "../components/modal/CardModal";
import PromotionModal from "../components/modal/PromotionModal";


const FormItem = Form.Item;

class Franchise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardOpenModal: false,
            promotionOpenModal: false,
        };
        this.formRef = React.createRef();
    }

    // onFinish = (e) => {
    //     alert(JSON.stringify(this.formRef.current.getFieldsValue()));
    // }

    OnCreateInquiry = (e) => {
        let info = JSON.stringify({
            ...this.formRef.current.getFieldsValue(),
            //추가 지정해서 보내기

            category: 'FRAN',
            title: '가맹점 문의',
            content: '주소:' + this.formRef.current.getFieldValue('address')
                + ', 운영시간:' + this.formRef.current.getFieldValue('worktime')
                + ', 배달건수:' + this.formRef.current.getFieldValue('deliverycount')
                + ', 내용:' + this.formRef.current.getFieldValue('content')
        });

        // alert(info);
        // return;
        // let {form} = this.props;

        // e.preventDefault();
        // form.validateFields((err, values) => {
        //   if (!err) {
        //     alert(JSON.stringify(values));

        //   }
        // });
        // return;

        const self = this;
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            content: "작성하신 내용을 전송하시겠습니까?",
            okText: '확인',
            cancelText: '취소',
            onOk() {
                httpPost(httpUrl.inquiryCreate, [], info).then(response => {
                    Modal.success({
                        content: (
                            <div style={{ paddingLeft: '40px' }}>
                                문의가 성공적으로 접수되었습니다.<br />
                                담당자가 빠른 시일내에 연락드릴 예정입니다.
                            </div>
                        ),
                        okText: '닫기'
                    });
                    self.formRef.current.resetFields();
                }).catch(e => {
                    Modal.error({
                        content: (
                            <div style={{ paddingLeft: '40px' }}>
                                작성에 실패하였습니다. <br />잠시 후 다시 시도해주세요.
                            </div>
                        ),
                        okText: '닫기'
                    });
                })
            },
            onCancel() {
            },
        });

    }

    // 프로모션보기 클릭시
    openPromotionModal = () => {
        this.setState({ promotionOpenModal: true });
    };
    closePromotionModal = () => {
        this.setState({ promotionOpenModal: false });
    };

    // 상세보기 클릭시 
    openCardModal = () => {
        this.setState({ cardOpenModal: true });
    };
    closeCardModal = () => {
        this.setState({ cardOpenModal: false });
    };

    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 17 }
            }
        };
        return (
            <div className="main">
                <div className="wrapper franchise-top-wrapper">
                    <div className="container franchise-top-container">
                        <div className="top-logo-wrapper">
                            <Link to='/'>
                                <img src={require('../img/common/logo.png').default} />
                            </Link>
                        </div>
                        <div className="franchise-top-menu">
                            <div className="franchise-top-menu-item desk">
                                <Link to='/'>
                                    <img src={require('../img/common/icon_home.png').default} />
                                </Link>
                            </div>

                            <div className="franchise-top-menu-item2">가맹점</div>

                            <Link to='/rider'>
                                <div className="franchise-top-menu-item3">라이더</div>
                            </Link>
                            <Link to='/expert'>
                                <div className="top-menu-item">전문가상담</div>
                            </Link>
                        </div>
                        {/* <div className="franchise-top-title-01">new</div> */}
                        <div className="franchise-top-title-01 desk">냠냠박스는 24시간 잠들지 않고, </div>
                        <div className="franchise-top-title-01 mobile">냠냠박스는 24시간 <br />잠들지 않고,</div>
                        <div className="franchise-top-title-02">기다리고 있습니다. </div>
                        <div className="franchise-top-title-03">
                            가맹점과 고객의 소중한 연결고리
                            <br />
                            "믿을 수 있습니다." 그 말만 듣겠습니다.
                        </div>
                        {/* <div className="franchise-top-button">
                            <div className="franchise-top-button-item" onClick={() => this.props.alert.show('오픈예정입니다.')}>
                                <img src={require('../img/common/btn_playstore.png').default} />
                            </div>
                            <div className="franchise-top-button-item2" onClick={() => this.props.alert.show('오픈예정입니다.')}>
                                <img src={require('../img/common/btn_appstore.png').default} />
                            </div>
                        </div> */}
                        <div className="top-bg2">
                            {/* <Carousel effect="fade" autoplay> */}
                            <div>
                                <img src={require('../img/common/franchise/franchise_top_bg4.png').default} />
                            </div>
                            {/* <div>
                                    <img src={require('../img/common/franchise/franchise_top_bg5.png').default} />
                                </div>
                                <div>
                                    <img src={require('../img/common/franchise/franchise_top_bg6.png').default} />
                                </div> */}
                            {/* </Carousel> */}
                        </div>
                        <div className="home-go-link-wrapper">
                            <AnchorLink href='#content'>
                                <div className="home-go-link"></div>
                            </AnchorLink>
                        </div>
                    </div>
                </div>

                <div className="franchise1-bg-img" id="content">
                    <div className="wrapper franchise1-wrapper">
                        <div className="container franchise1-container">
                            <div className="sub1-img-circle desk">
                                <img src={require('../img/common/yellow_circle.png').default} />
                            </div>
                            <div className="franchise1-img">
                                <RevealFade left duration={1500}>
                                    <img className="franchise1-img-main" src={require('../img/common/franchise/sub1_bg3.png').default} />
                                </RevealFade>
                            </div>
                            <div className="franchise1-content">
                                <div className="franchise1-text1">
                                    매일 만나는 즐거운 소리, 냠냠박스
                                </div>
                                {/* <div className="franchise1-text2">
                                    매장의 정성이 담긴 맛있는 음식을 소비자들에게 전해 드립니다.
                            </div> */}
                                <div className="franchise1-block">
                                    <div className="franchise1-block-text">
                                        <div className="main-dots2" />
                                        매장에서 먹었던 정성 그대로 소비자들에게 전달해 드립니다.
                                    </div>
                                    <div className="franchise1-block-text">
                                        <div className="main-dots2" />
                                        정기적인  라이더 교육을 통해 최상의 서비스를 제공합니다.
                                    </div>
                                    <div className="franchise1-block-text">
                                        <div className="main-dots2" />
                                        각 지점의 현장이슈 신속하고 빠르게 대응합니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="container franchise2-container">
                        <div className="franchise2-img">
                            <RevealFade left duration={1500} delay={300}>
                                <img className="franchise2-img-main" src={require('../img/common/franchise/sub2_bg.png').default} />
                            </RevealFade>
                        </div>
                        <div className="franchise2-content">
                            <div className="franchise2-text1">
                                쉽고 빠른 인터페이스 프로그램
                            </div>
                            {/* <div className="franchise2-text2">
                                가맹점 사장님을 배려한 간단하고 편한 프로그램을 제공합니다.
                            </div> */}
                            <div className="franchise2-text3">
                                <div className="franchise2-block">
                                    {/* <div className="franchise2-block-text">
                                        <div className="main-dots2" />
                                        주문앱은 기본!! 전화주문까지 실시간 연동하여 원클릭 배송신청을 제공합니다.
                                    </div> */}
                                    <div className="franchise2-block-text">
                                        <div className="main-dots2" />
                                        사장님을 배려한 편리한 프로그램으로 누구나 쉽고 빠르게 조작할 수 있습니다.
                                    </div>
                                    <div className="franchise2-block-text">
                                        <div className="main-dots2" />
                                        AI인공지능 배차 시스템을 도입하여 신속하고 정확한 라이더 배정을 도와줍니다.
                                    </div>

                                </div>
                                {/* <ul>
                                    <li>주문앱과 실시간 연동을 통한 원 클릭 배송신청 제공합니다.</li>
                                    <li>주문 이력 연동을 통한 자동주소 입력을 제공합니다.</li>
                                    <li>AI 인공지능 배차 시스템을 도입해 신속하고 정확한 라이더 배정을 합니다.</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="container franchise2-container">
                        <div className="franchise2-img">
                            <RevealFade left duration={1500} delay={600}>
                                <img className="franchise2-img-main" src={require('../img/common/franchise/sub2_bg2.png').default} />
                            </RevealFade>
                        </div>
                        <div className="franchise2-content">
                            <div className="franchise2-text1">
                                복잡한 세금문제 걱정마세요!!
                            </div>
                            {/* <div className="franchise2-text2">
                                가맹점 사장님을 배려한 간단하고 편한 프로그램을 제공합니다.
                            </div> */}
                            <div className="franchise2-text3">
                                <div className="franchise2-block">
                                    <div className="franchise2-block-text">
                                        <div className="main-dots2" />
                                        냠냠박스와 업무협약을 맺은 한결세무법인에서 복잡하고 어려운 세무를 무료로 상담해드립니다.

                                    </div>
                                    <div className="franchise2-block-text">
                                        <div className="main-dots2" />
                                        기장 의뢰시 전국 최저비용인 월 5만원에 이용하실 수 있습니다.
                                    </div>
                                </div>
                                <br />
                                <div className="franchise2-block-btn">
                                    <div className="btn-primary" onClick={() => {
                                        this.props.history.push({
                                            pathname: '/expert',
                                            hash: '#content',
                                            state: { category: 1 }
                                        })
                                    }}>
                                        바로가기
                                    </div>
                                </div>
                                <div className="franchise2-img2">
                                    <img src={require('../img/common/franchise/line2.png').default} />
                                </div>
                                {/* <ul>
                                    <li>주문앱과 실시간 연동을 통한 원 클릭 배송신청 제공합니다.</li>
                                    <li>주문 이력 연동을 통한 자동주소 입력을 제공합니다.</li>
                                    <li>AI 인공지능 배차 시스템을 도입해 신속하고 정확한 라이더 배정을 합니다.</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="wrapper">
                    <div className="container franchise2-container">
                        <div className="franchise2-img">
                            <RevealFade left duration={1500} delay={900}>
                                <img className="franchise2-img-main" src={require('../img/common/franchise/sub2_bg3.png').default} />
                            </RevealFade>
                        </div>
                        <div className="franchise2-content">
                            <div className="franchise2-text1">
                                신용회복을 생각중이신가요??
                            </div>
                            <div className="franchise2-text2">
                                가맹점 사장님을 배려한 간단하고 편한 프로그램을 제공합니다.
                            </div> *
                            <div className="franchise2-text3">
                                <div className="franchise2-block">
                                    <div className="franchise2-block-text">
                                        <div className="main-dots2" />
                                        냠냠박스에서 라이더 및 가맹점주님들의 신용회복을 도와드리고자
                                        "법무법인 인"과 업무협약을 맺어 무료로 신용회복 상담을 해드립니다.


                                    </div>
                                    <div className="rider2-block-text">
                                        <div className="main-dots2" />
                                        신용회복 법무비용을 본사에서 100만원 지원해 드립니다.

                                    </div>
                                    <br />
                                    <div className="franchise2-block-btn">
                                        <div className="btn-primary" onClick={() => {
                                            this.props.history.push({
                                                pathname: '/expert',
                                                hash: '#content',
                                                state: { category: 2 }
                                            })
                                        }}>
                                            바로가기
                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>주문앱과 실시간 연동을 통한 원 클릭 배송신청 제공합니다.</li>
                                    <li>주문 이력 연동을 통한 자동주소 입력을 제공합니다.</li>
                                    <li>AI 인공지능 배차 시스템을 도입해 신속하고 정확한 라이더 배정을 합니다.</li>
                                </ul>
                            </div>
                            <div className="franchise2-img2">
                                <img src={require('../img/common/franchise/line2.png').default} />
                            </div>
                        </div>
                    </div>
                </div> */}




                {/* <div className="wrapper franchise3-wrapper">
                    <div className="container franchise3-container">
                        <div className="franchise3-title">냠냠박스 24시간 지원</div>
                        <div className="franchise3-text">우리는 관제센터에서 24시간 모니터링을 통해 서비스 질을 향상 시키고자 합니다.</div>
                        <div className="franchise3-block-wrapper">
                            <div className="franchise3-block" style={{ backgroundColor: '#fbdd05' }}>
                                <img src={require('../img/common/franchise/sub3_item1.png').default} />
                                <div className="franchise3-block-title">직영점 운영</div>
                                <div className="franchise3-block-text">
                                    <div className="main-dots" />
                                        모든 라이더들이 교통법규를 잘 지키며
                                        <br />
                                        안전한 배달대행 문화 형성에 앞장 섭니다.
                                </div>
                            </div>
                            <div className="franchise3-block-center">
                                <img src={require('../img/common/franchise/sub3_item2.png').default} />
                                <div className="franchise3-block-title">서비스 관리</div>
                                <div className="franchise3-block-text">
                                    <div className="main-dots" />
                                    체계적인 관리 시스템을 갖추어 서비스 품질관리 및 전문성을 강화하였습니다.
                                </div>
                            </div>
                            <div className="franchise3-block">
                                <img src={require('../img/common/franchise/sub3_item3.png').default} />
                                <div className="franchise3-block-title">지역연구</div>
                                <div className="franchise3-block-text">
                                    <div className="main-dots" />
                                    지역에서 발생하는 배달 서비스에 관한 모든 문제를 신속하고 정확하게 해결합니다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="wrapper franchise4-wrapper" id="asking">
                    <div className="container franchise4-container">
                        <div className="franchise4-left">
                            <div className="franchise4-title">가맹점 문의</div>
                            <div className="franchise4-text">
                                냠냠박스는 가맹점과 함께 성장하기를 바라며 <br />
                                새로운 배달문화에 조성에 앞장서 나가겠습니다.
                            </div>
                        </div>
                        <div className="franchise4-right">
                            <Form ref={this.formRef} {...formItemLayout} onFinish={this.OnCreateInquiry}>
                                <div className="franchise4-right-input">
                                    <div className="franchise4-right-input-title">
                                        이름
                                    </div>
                                    <FormItem
                                        name="name"
                                        rules={[{ required: true, message: '이름을 입력해주세요' }]}>
                                        <input className="franchise4-input-box" />
                                    </FormItem>
                                    {/* <input type="text" id="franchise4-input"
                                /> */}
                                </div>

                                <div className="franchise4-right-input">
                                    <div className="franchise4-right-input-block">
                                        <div className="franchise4-right-input-title2">
                                            연락처
                                        </div>
                                        <FormItem
                                            name="phone"
                                            rules={[{ required: true, message: '연락처를 입력해주세요' }]}
                                        >
                                            <input className="franchise4-input-box2" />
                                        </FormItem>
                                        {/* <input type="text" id="franchise4-input2"/> */}
                                    </div>
                                    <div className="franchise4-right-input-block2">
                                        <div className="franchise4-right-input-title2">
                                            매장명
                                        </div>
                                        <FormItem
                                            name="company"
                                            rules={[{ required: true, message: '회사명을 입력해주세요' }]}
                                        >
                                            <input className="franchise4-input-box2"
                                                rows={5} />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="franchise4-right-input">
                                    <div className="franchise4-right-input-block">
                                        <div className="franchise4-right-input-title2">
                                            주소
                                        </div>
                                        <FormItem
                                            name="address"
                                            rules={[{ required: true, message: "주소를 입력해주세요" }]}
                                        >
                                            <input className="franchise4-input-box" />
                                        </FormItem>
                                    </div>
                                    {/* <div className="franchise4-right-input-block2">
                                    <div className="franchise4-right-input-title2">
                                        
                                    </div>
                                    <input type="text" id="franchise4-input2"/>
                                </div> */}
                                </div>

                                <div className="franchise4-right-input">
                                    <div className="franchise4-right-input-block">
                                        <div className="franchise4-right-input-title2">
                                            운영시간
                                        </div>
                                        <FormItem
                                            name="worktime"
                                            rules={[{ required: true, message: "운영시간을 입력해주세요" }]}
                                        >
                                            <input className="franchise4-input-box2" />
                                        </FormItem>
                                    </div>
                                    <div className="franchise4-right-input-block2">
                                        <div className="franchise4-right-input-title2">
                                            주중/주말 배달건수
                                        </div>
                                        <FormItem
                                            name="deliverycount"
                                            rules={[{ required: true, message: "주중/주말 배달건수를 입력해주세요" }]}
                                        >
                                            <input className="franchise4-input-box2" />
                                        </FormItem>
                                    </div>
                                </div>
                                <div>
                                    <div className="franchise4-right-input-title2">
                                        문의내용
                                    </div>
                                    <div className="franchise4-right-input-ask">
                                        <FormItem
                                            name="content"
                                            rules={[{ required: true, message: "문의내용을 입력해주세요" }]}
                                        >
                                            <textarea className="rider4-input-box3" autoSize={{ minRows: 3, maxRows: 100 }} />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="franchise4-right-input-check">
                                    <button className="franchise4-right-input-button" type="primary" htmlType="submit">
                                        보내기
                                    </button>
                                </div>
                            </Form>
                        </div>


                    </div>

                </div>

                {/* <Affix offsetBottom={0} onChange={affixed => console.log(affixed)}>
                    <div className="franchise5-wrapper">
                        <div className="franchise5-container">
                            <div className="franchise5-box">
                                <div className="franchise5-block">
                                    <div>연회비 ZERO!</div>
                                    <div>삼성카드 CORPORATE#2 (냠냠박스)</div>
                                    <div className="franchise5-block-txt">
                                        <div>배달료 충전만 해도 0.2% 캐시백</div>
                                        <div>*배달료 이외 용도 사용해도 적립가능</div>
                                    </div> */}
                {/* <div className="franchise5-footer">
                                        <div onClick={this.openPromotionModal}>[ 프로모션 보기 ]</div>
                                    </div> */}
                {/* <div className="franchise5-btn">
                                        <div onClick={this.openCardModal}>상세보기</div>
                                        <a href="http://www.samsungcard.com/corporation/card/biz-card/UHPCCA0201M0.jsp?fncPdC=AA19149" download>신청하기</a>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'inline-block', backgroundColor: 'black' }}>
                                <img src={require('../img/banner_logo.png').default} />
                            </div>
                        </div>
                    </div>
                </Affix> */}

                <PromotionModal
                    isOpen={this.state.promotionOpenModal}
                    close={this.closePromotionModal}
                />

                <CardModal
                    isOpen={this.state.cardOpenModal}
                    close={this.closeCardModal}
                />


            </div >
        )
    }
}

export default withAlert()(withRouter(Franchise));
