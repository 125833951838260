import React from 'react';
import { withRouter, Link } from "react-router-dom";
import { httpGet, httpPost, httpUrl } from '../api/httpClient';
import { Form, Modal, Input, DatePicker, Descriptions, Upload, Button, Select, Icon, Radio, Carousel , Text } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { withAlert } from 'react-alert';
import RevealSlide from 'react-reveal/Slide';
import RevealFade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import Flip from 'react-reveal/Flip';
const Option = Select.Option;
const FormItem = Form.Item;

class Expert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.formRef = React.createRef();
    }

    // onFinish = (e) => {
    //     alert(JSON.stringify(this.formRef.current.getFieldsValue()));
    // }

    componentDidMount() {
        if (this.props.location.hash && this.props.location.hash.length > 1) {
            let targetId = this.props.location.hash.substr(1, this.props.location.hash.length);
            window.requestAnimationFrame(()=>{
                if (document.getElementById(targetId))
                    document.getElementById(targetId).scrollIntoView(true);
            })
        }
    }
    OnCreateInquiry = (e) => {

        const categoryString = [
            '',
            '세무상담',
            '법률상담',
            '기타문의',
        ]
        const categoryCodeString = [
            '',
            'TAX',
            'LAW',
            'EXP',
        ]
        const typeString = [
            '',
            '가맹점',
            '라이더',
        ]

        let info = JSON.stringify({
            category: categoryCodeString[this.formRef.current.getFieldValue('category')],
            title: '전문가 상담신청',
            content: '이름:' + this.formRef.current.getFieldValue('name')
                + ', 문의종류:' + categoryString[this.formRef.current.getFieldValue('category')]
                + ', 연락처:' + this.formRef.current.getFieldValue('phone')
                + ', 상담신청자유형:' + typeString[this.formRef.current.getFieldValue('type')]
                + ', 내용:' + this.formRef.current.getFieldValue('content')
        });
        console.log(info)
        const self = this;
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: "작성하신 내용을 전송하시겠습니까?",
            okText: '확인',
            cancelText: '취소',
            onOk() {
                httpPost(httpUrl.inquiryCreate, [], info).then(response => {
                    Modal.success({
                        content: (
                            <div style={{paddingLeft: '40px'}}>
                            문의가 성공적으로 접수되었습니다.<br/>
                            담당자가 빠른 시일내에 연락드릴 예정입니다.
                          </div>
                        ),
                        okText: '닫기'
                      });
                      self.formRef.current.resetFields();
                }).catch(e => {
                    Modal.error({
                        content: (
                          <div style={{paddingLeft: '40px'}}>
                            작성에 실패하였습니다. <br/>잠시 후 다시 시도해주세요.
                          </div>
                        ),
                        okText: '닫기'
                      });
                })
            },
            onCancel() {
            },
          });

    }
    render() {
        const { form } = this.props;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 17 }
            }
        };
        return (
            <div className="main">
                <div className="wrapper rider-top-wrapper">
                    <div className="container rider-top-container">
                        <div className="top-logo-wrapper">
                            <Link to='/'>
                                <img src={require('../img/common/logo.png').default} />
                            </Link>
                        </div>
                        <div className="rider-top-menu">
                            <div className="rider-top-menu-item desk">
                                <Link to='/'>
                                    <img src={require('../img/common/icon_home.png').default} />
                                </Link>
                            </div>

                            <Link to='/franchise'>
                                <div className="rider-top-menu-item2">가맹점</div>
                            </Link>

                            <Link to='/rider'>
                                <div className="franchise-top-menu-item3">라이더</div>
                            </Link>
                            
                            <div className="rider-top-menu-item3">전문가상담</div>
                        </div>
                        {/* <div className="rider-top-title-01">new</div> */}
                        <div className="rider-top-title-01">냠냠박스</div>
                        <div className="rider-top-title-02">세무, 법률상담</div>
                        <div className="rider-top-title-03">
                        전문가와 무료상담 받으세요

                            </div>
                        <div className="rider-top-button" style={{visibility: 'hidden'}}>
                            <div className="rider-top-button-item" onClick={() => this.props.alert.show('오픈예정입니다.')}>
                                <img src={require('../img/common/btn_playstore.png').default} />
                            </div>
                            <div className="rider-top-button-item2" onClick={() => this.props.alert.show('오픈예정입니다.')}>
                                <img src={require('../img/common/btn_appstore.png').default} />
                            </div>
                        </div>
                        <div className="top-bg3">
                            <div>
                            <img src={require('../img/common/b2b/b2b_top_bg.png').default} />
                            </div>
                        </div>
                        <div className="home-go-link-wrapper">
                            <AnchorLink href='#content'>
                                <div className="home-go-link"></div>
                            </AnchorLink>
                        </div>
                    </div>
                </div>
                <div className="wrapper sub8-wrapper" id="content">
                    <div className="container sub8-container">
                        <div className="sub8-left">
                            <div className="sub8-title">전문가 상담신청</div>
                            <div className="sub8-text">
                                냠냠박스에서는 라이더와 가맹점을 위해<br/> 
                                각 분야의 전문가들이 상담을 지원합니다.
                            </div>
                        </div>
                        <div className="sub8-right">
                            <Form ref={this.formRef} {...formItemLayout} onFinish={this.OnCreateInquiry}>
                                <div className="sub8-right-input">
                                    <div className="sub8-right-input-block">
                                        <div className="sub8-right-input-title">
                                            문의종류
                                        </div>
                                        <FormItem
                                            name="category"
                                            rules={[{ required: true, message: '' }]}
                                            initialValue={this.props.location.state && this.props.location.state.category ? this.props.location.state.category : 1}>
                                            <select className="rider4-input-select">
                                                <option key={1} value={1}>세무상담</option>
                                                <option key={2} value={2}>법률상담</option>
                                                <option key={3} value={3}>기타문의</option>
                                            </select>
                                        </FormItem>
                                    </div>
                                    <div className="sub8-right-input-block2">
                                        <div className="sub8-right-input-title">
                                            이름
                                        </div>
                                        <FormItem
                                            name="name"
                                            rules={[{ required: true, message: '이름을 입력해주세요' }]}>
                                            <input className="sub8-right-inputbox2" />
                                        </FormItem>
                                        {/* <input type="text" id="sub8-input"
                                    /> */}
                                    </div>
                                </div>

                                <div className="sub8-right-input">
                                    <div className="sub8-right-input-block">
                                        <div className="sub8-right-input-title2">
                                            연락처
                                        </div>
                                        <FormItem
                                            name="phone"
                                            rules={[{ required: true, message: '연락처를 입력해주세요' }]}
                                        >
                                            <input className="sub8-right-inputbox2" />
                                        </FormItem>
                                        {/* <input type="text" id="franchise4-input2"/> */}
                                    </div>
                                    <div className="rider4-right-input-block2">
                                        <div className="rider4-right-input-title2">
                                            상담신청자 유형
                                    </div>
                                        <FormItem
                                            name="type"
                                            rules={[{ required: true, message: '' }]}
                                            initialValue={1}
                                            >

                                            <Radio.Group className="rider4-input-radio">
                                                <Radio value={1}>가맹점</Radio>
                                                <Radio value={2}>라이더</Radio>
                                            </Radio.Group>
                                        </FormItem>
                                    </div>
                                </div>

                                <div>
                                    <div className="sub8-right-input-title2">
                                        문의내용 (*원하는 상담 시간을 함께 기재해 주세요.)
                                </div>
                                    <FormItem
                                        name="content"
                                        rules={[{ required: true, message: "문의내용을 입력해주세요" }]}
                                    >
                                    <textarea className="rider4-input-box3" placeholder="세무, 법률 등의 궁금한 사항을 남겨주세요."/>
                                    </FormItem>
                                </div>

                                <div className="sub8-right-input">
                                    {/* <div className="sub8-right-input-check">
                                        <img className="sub8-check-img" src={require('../img/common/input_check.png').default} />
                                    약관동의
                                </div> */}

                                    <button className="sub8-right-input-button" type="primary" htmlType="submit">
                                        보내기
                                    </button>
                                </div>
                            </Form>
                        </div>


                    </div>

                </div>
            </div>
        )
    }
}

export default withAlert()(withRouter(Expert));