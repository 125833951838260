import React, { Component } from "react";
import '../../src/css/nnbox.scss';
import '../../src/css/nnbox_m.scss';
import { Select } from "antd";
import FormItem from "antd/lib/form/FormItem";


const { Option } = Select;
const options = [
    { value: '매장천사', label: '매장천사' },
    { value: '메이트포스', label: '메이트포스' },
    { value: '바나나포스', label: '바나나포스' },
    { value: '배달특급', label: '배달특급' },
    { value: '배달천재', label: '배달천재' },
    { value: '아이노포스(지니포스)', label: '아이노포스(지니포스)' },
    { value: '인천 이음', label: '인천 이음' },
    { value: '포스피드', label: '포스피드' },
    { value: '푸드테크', label: '푸드테크' },
    { value: '롯데리아', label: '롯데리아' },
    { value: '본죽&본도시락', label: '본죽&본도시락' },
    { value: 'spc', label: 'spc' },
    { value: '오케이포스(자담치킨)', label: '오케이포스(자담치킨)' },
];



class Guide extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOption: "매장천사",
        }
    }
    handleChange = (selectedOption) => {
        this.setState({ selectedOption }, () =>
            console.log(`Option selected:`, this.state.selectedOption)
        );
    };
    render() {
        const { isOpen, close } = this.props;
        const { selectedOption } = this.state;
        return (
            <>
                <div className="guide-wrapper" onClick={close} />
                <div className="guide-wrapper-inner">
                    <div className="">
                        <div className="container">
                            <div className="guide-logo-wrapper">
                                <img src={require("../img/common/logo.png").default} />
                            </div>
                            <div className="guide-title">
                                <div>연동 가이드</div>
                                {/* <div className="guide-image" onClick={close}>
                            <img src={require('../img/common/close.png').default} alt="" />
                        </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="guide-content">
                        <div className="guideMenu">
                            <Select
                                style={{ width: 200 }}
                                value={selectedOption}
                                onChange={this.handleChange}
                                options={options}
                            />
                            <div className="guide-select desk">* 연동항목을 골라주세요.</div>
                        </div>
                        {/* 요약 컨텐츠 */}
                        {this.state.selectedOption == "매장천사" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            매장천사
                                        </div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:1688-7097'><img src={require('../img/common/telephone.png').default} />1688-7097</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ블릭트 설치 X 배민연동 프로그램 설치 X<br />
                                        <br />
                                        ㆍ가맹점 시스템 포스연동 OFF / 서브포스 OFF<br />
                                        <br />

                                        <div className="guide-tip">* 매장천사 연동 풀리는 현상 / 주소 오류 발생시 *</div>
                                        ㆍ배달의 민족, 요기요와 매장천사는 정식 연동이 아니기 때문에 우리 연동 프로그램 설치해서 직접 연동가능
                                        (기존 가맹점들 별 문제 없이 사용중)<br /><p></p>
                                        ㆍ매장천사 API 연동 체크 해제(매장천사 프로그램 => 환경설정)<br /><p></p>
                                        ㆍ배달의 민족 직접 연동은 배민 연동 프로그램 설치 O / 포스연동 ON<br /><p></p>
                                        ㆍ요기요 직접 연동은 블릭트 설치 O / 서브포스 ON<br /><p></p>

                                    </div>


                                </div>
                            </div>
                        }
                        {this.state.selectedOption == "메이트포스" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            메이트포스
                                        </div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:1877-1528'><img src={require('../img/common/telephone.png').default} />1877-1528</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ블릭트 설치 X 배민연동 프로그램 설치 X<br />
                                        <br />
                                        ㆍ관제 프로그램에서 푸드테크 연동 설정 후 메이트포스 고객센터로 전화하여 기존 대행사 해지 및 냠냠박스 변경 요청

                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "바나나포스" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            바나나포스
                                        </div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:031-715-7200'><img src={require('../img/common/telephone.png').default} /> 031-715-7200</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ블릭트 설치 X 배민연동 프로그램 설치 X<br />
                                        <br />
                                        ㆍ관제 프로그램에서 바나나포스 연동 설정 후 와이드베네피플(바나나포스) 고객센터로 전화하여
                                        기존 대행사 해지 및 냠냠박스 변경 요청<br />
                                        <br />
                                        <div className="guide-tip">* TIP *</div>
                                        ㆍ카카오톡 채널 추가하여 상담 가능<br /><p></p>
                                        ㆍ바나나포스는 배달요청시간 15분 고정이므로 가맹점주께 설명<br /><p></p>
                                        ㆍ연동 코드 발급 소요 시간 최대 15분<br /><p></p>
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "배달특급" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            배달특급</div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:1599-9836'><img src={require('../img/common/telephone.png').default} />1599-9836</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ관제 프로그램에서 스파이더 연동 설정<br />
                                        <br />
                                        ㆍ배달특급 고객센터로 전화하여 기존 대행사 해지 및 냠냠박스 변경 요청 <br />
                                        <br />
                                        <div className="guide-tip">* TIP *</div>
                                        ㆍ배민 사용하는 가맹점은 배민연동 프로그램 설치 O<br /><p></p>
                                        ㆍ요기요 사용하는 가맹점은 블릭트 설치 O (블릭트 포트 번호 동일하게 설정)<br /><p></p>
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "배달천재" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            배달천재
                                        </div>
                                    </div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ블릭트 설치 X 배민연동 프로그램 설치 X<br />
                                        <br />
                                        ㆍ관제 프로그램에서 배달천재 연동 설정 후 배달천재 프로그램 환경설정에서 배달대행사 냠냠박스 체크<br />
                                        <br />
                                        <div className="guide-tip">* TIP *</div>
                                        ㆍ인터넷으로 지오테크놀로지 검색<br /><p></p>

                                        ㆍ<a href="https://www.gotech.kr" target="_blank">https://www.gotech.kr </a>접속하여 1대1 고객상담 요청<br /><p></p>

                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "아이노포스(지니포스)" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            아이노포스(지니포스)
                                        </div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:0505-332-3328'><img src={require('../img/common/telephone.png').default} />0505-332-3328</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ블릭트 설치 X 배민연동 프로그램 설치 X<br />
                                        <br />
                                        ㆍ관제 프로그램에서 아이노포스(지니포스) 연동 설정 후 솔루피온 고객센터로 전화하여 기존 대행사 해지 및 냠냠박스 변경 요청  <br />
                                        <br />

                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "인천 이음" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            인천 이음
                                        </div>
                                    </div>
                                    <div className="guideMenu-01-bot-txt desk">
                                        ㆍ이음 프로그램에서 대행사 변경 후 관제 프로그램에서 코나아이 연동 설정<br />
                                        <br />
                                        ㆍ코나아이 고객센터로 전화하여 기존 대행사 해지 및 냠냠박스 변경 요청(2~3일 소요)    <br />
                                        <br />
                                        <div className="guide-tip">* TIP *</div>
                                        ㆍ중부(부평, 미추홀, 동구, 계양, 강화, 옹진) 1811-6412<br /><p></p>
                                        ㆍ남부(연수, 남동, 중구) 1811-9803<br /><p></p>
                                        ㆍ북부(서구) 1811-9457<p></p>
                                    </div>
                                    <div className="guideMenu-01-bot-txt mobile">
                                        ㆍ이음 프로그램에서 대행사 변경 후 관제 프로그램에서 코나아이 연동 설정<br />
                                        <br />
                                        ㆍ코나아이 고객센터로 전화하여 기존 대행사 해지 및 냠냠박스 변경 요청(2~3일 소요)    <br />
                                        <br />
                                        <div className="guide-tip">* TIP *</div>
                                        ㆍ중부(부평, 미추홀, 동구, 계양, 강화, 옹진) <br />1811-6412<br /><p></p>
                                        ㆍ남부(연수, 남동, 중구) <br />1811-9803<br /><p></p>
                                        ㆍ북부(서구)<br /> 1811-9457<p></p>
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "포스피드" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            포스피드
                                        </div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:1599 6884'><img src={require('../img/common/telephone.png').default} />1599 6884</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ블릭트 설치 X 배민연동 프로그램 설치 O<br />
                                        <br />
                                        ㆍ관제 프로그램에서 포스피드 연동 설정 후 카카오톡 채널 포스피드 추가하여 카카오톡으로 기존 대행사 해지 및 냠냠박스 변경 요청 <br />
                                        <br />
                                        ㆍ<span>가맹점 프로그램 환경설정에서 포스피드 ON</span><br />
                                        <br />
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "푸드테크" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            푸드테크
                                        </div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:1600-0096'><img src={require('../img/common/telephone.png').default} />1600-0096</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ블릭트 설치 X 배민연동 프로그램 설치 X<br />
                                        <br />
                                        ㆍ관제 프로그램에서 푸드테크 연동 설정 후 푸드테크 고객센터로 전화하여 기존 대행사 해지 및 냠냠박스 변경 요청 <br />
                                        <br />
                                        <div className="guide-tip">* TIP *</div>
                                        ㆍ푸드테크 연동 완료시 별도 테스트 없이 푸드테크 프로그램의 가상계좌와 냠냠박스 가맹점 프로그램의 가상계좌가 같으면 연동 완료.<br />
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "롯데리아" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            롯데리아
                                        </div>
                                    </div>
                                    <div className="guide-tel"><a href='tel:02-2626-6814'><img src={require('../img/common/telephone.png').default} />02-2626-6814</a></div>
                                    <div className="guideMenu-01-bot-txt">
                                        ㆍ롯데리아 매장 POS 화면 하단 대행사 설정 메뉴에서 기존 대행사 연동 해지 후 냠냠박스 체크<br />
                                        <br />
                                        ㆍ가맹점주에게 점포코드 확인 및 홈서비스 전화하여 연동요청하기. 코드 ex) LA123456  <br />
                                        <br />
                                        ㆍ연동 코드는 LA 제외한 숫자    <br />
                                        <br />
                                        ㆍ관제 프로그램에서 연동 설정하면서 연동 코드 수동 입력 <br />
                                        <br />
                                        <div className="guide-tip">* TIP *</div>
                                        ㆍ연동 코드 입력이 안될 경우 연동 삭제 후 다시 시도<br />
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "본죽&본도시락" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            본죽&본도시락
                                        </div>
                                    </div>
                                    <div className="guideMenu-01-bot-txt">
                                        <b> **본죽&본도시락 연동설정 방법 안내**</b><br />
                                        <br />
                                        1.관제시스템 가맹점관리에서 매장연동설정에서 본아이에프(본죽)을 추가한다.   <br />
                                        <br />
                                        2. 프로그램방에서 가맹점명과 사업자번호 작성하여 연동코드 매핑해달라고 요청한다. <br />
                                        <br />

                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "spc" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            spc
                                        </div>
                                    </div>
                                    <div className="guideMenu-01-bot-txt">
                                        <b>**spc 연동설정 방법 안내**</b><br />
                                        <br />
                                        1. 관제시스템 가맹점관리에서 매장연동설정에서 spc를 추가한다.   <br />
                                        <br />
                                        2. spc는 b2b건이라 계약이 완료된 가맹점에 한해서 연동이 가능하다.   <br />
                                        <br />
                                        3. 2번이 완료된 가맹점에 한해서 운영팀 김성민 매니저한테 요청해서 연동코드 매핑해달라고 요청한다. <br />
                                        <br />
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.selectedOption == "오케이포스(자담치킨)" &&
                            <div className="guideMenu-01">
                                <div className="guideMenu-01-bot">
                                    <div className="guide-box">
                                        <div className="guideMenu-01-bot-title">
                                            오케이포스(자담치킨)
                                        </div>
                                    </div>
                                    <div className="guideMenu-01-bot-txt">
                                        <b>**오케이포스(자담치킨) 연동설정 방법 안내**</b><p></p>
                                        오케이포스 시스템을 사용하는 가맹점에 한해서 정식연동이 가능!!<br />
                                        <br />
                                        1. 관제시스템 가맹점관리에서 매장연동설정에서 오케이포스를 추가한다. <br />
                                        <br />
                                        2. 오케이포스 고객센터 <a href='tel:1544-3640'>1544-3640</a>으로 연락해서 연동코드 매핑해달라고 요청한다. <br />
                                        <br />
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </>


        )
    }
}

export default (Guide)

