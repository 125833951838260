import React, { Component } from 'react';
import { Link } from "react-router-dom";
import SalesAgentModal from "./modal/SalesAgentModal";

export default class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            salesAgentModalOpen: false,
        }
    }

    render() {
        return (
            <div className="wrapper footer-wrapper">
                <div className="container footer-container">
                    <div className="footer-logo">
                        <Link to='/'>
                            <img src={require('../img/common/logo_white.png').default} />
                        </Link>
                    </div>
                    <div className="footer-content">
                        {/* <div className="footer-link">
                            <Link to='/franchise'>
                                <div className="footer-link-text1">
                                    가맹점
                            </div>
                            </Link>

                            <div className="footer-link-dots">

                            </div>
                            <Link to='/rider'>
                                <div className="footer-link-text2">
                                    라이더
                            </div>
                            </Link>
                        </div> */}
                        <div className="footer-title">(주)커넥트9</div>
                        <span className="footer-text">
                            본사: 서울특별시 강남구 도산대로 235(신사동) 5층<br />
                            김포 지원센터: 경기도 김포시 양촌읍 석모리 341<br />
                            {/* 김포 구래점: 경기도 김포시 김포한강4로 535<br />
                            김포 장기점: 경기도 김포시 장기동1900-17<br />
                            김포 풍무점: 경기도 김포시 풍무1로 73<br />
                            김포 감정분점: 경기도 김포시 중봉1로 17<br />
                            김포 고촌분점: 경기도 김포시 고촌읍 장차로5번길33<br />
                            인천 송도점: 인천광역시 연수구 테크노파크로 113<br /> */}
                            대표: 김정환 | 사업자번호: 547-88-02107<br />
                            대표전화: 1600-5453 | 이메일: mot@connect9.co.kr<br />
                            통신판매업신고: 2021-서울강남-02970<br />
                            <a href="/privacy" target="_blank">개인정보보호정책</a>
                            
                            <a  style={{marginLeft: 10}}
                            onClick={() => this.setState({
                                salesAgentModalOpen: true,
                            })}>판매대리·중개 업무위탁 증서</a>
                            
                        </span>
                        {this.state.salesAgentModalOpen &&
                            <SalesAgentModal
                              close={() => this.setState({
                                    salesAgentModalOpen: false,
                                })} />}
                        <div className="footer-text">Copyright © Connect9 Corp. All Rights Reserved.</div>
                        {/* <div className="footer-download">
                            <a href="/안전보건목표와 경영방침.zip" download>
                                <img src={require('../img/common/download.png').default} />
                                안전보건목표와 경영방침

                            </a>
                        </div> */}
                    </div>

                </div>
            </div>
        );
    }
}
